import { render, staticRenderFns } from "./user-invite-code-record.vue?vue&type=template&id=7a5d008c&scoped=true"
import script from "./user-invite-code-record.vue?vue&type=script&lang=js"
export * from "./user-invite-code-record.vue?vue&type=script&lang=js"
import style0 from "./user-invite-code-record.vue?vue&type=style&index=0&id=7a5d008c&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7a5d008c",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\work\\fb_admin_web\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('7a5d008c')) {
      api.createRecord('7a5d008c', component.options)
    } else {
      api.reload('7a5d008c', component.options)
    }
    module.hot.accept("./user-invite-code-record.vue?vue&type=template&id=7a5d008c&scoped=true", function () {
      api.rerender('7a5d008c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/huoshan/user-invite-code-record.vue"
export default component.exports