<template>
  <div class="panel-ranking">
    <div>
      <div class="table-title">用户活跃列表</div>
      <div-table H="300px" :fromList="columns1" :fromData="userList" :pageSize="pageSize" :pageValue="pageValue"
        :pageSizeList="pageSizeList" :total="total" @up-page-size="pageSize = $event"
        @up-page-value="pageValue = $event" @get-message="getMessage($event)">
      </div-table>
    </div>

    <div>
      <div class="table-title">邀请人列表排行榜</div>
      <div-table H="300px" :fromList="columns2" :fromData="inviteUserList" :pageSize="inviteUserPageConfig.pageSize" :pageValue="inviteUserPageConfig.pageValue"
        :pageSizeList="inviteUserPageConfig.pageSizeList" :total="inviteUserPageConfig.total" @up-page-size="inviteUserPageConfig.pageSize = $event"
        @up-page-value="inviteUserPageConfig.pageValue = $event" @get-message="getInviteUserList($event)">
        <template v-slot:operate="{ data }">
        <div class="operate flex-center-center">
          <el-link
            type="primary"
            @click="viewInviteUser(data.rows)"
            >查看</el-link
          >
        </div>
      </template>
      </div-table>
    </div>

    <div class="table" v-for="table in tableList">
      <div class="table-title">{{ table.title }}</div>
      <div-table :fromList="table.columns" :fromData="table.list" :info-page="false" H="300px"></div-table>
    </div>

    <el-dialog title="邀请人明细" :visible.sync="dialogTableVisible">
      <div-table H="300px" :fromList="columns3" :fromData="inviteUserDetailList" :pageSize="inviteUserDetailPageConfig.pageSize" :pageValue="inviteUserDetailPageConfig.pageValue"
        :pageSizeList="inviteUserDetailPageConfig.pageSizeList" :total="inviteUserDetailPageConfig.total" @up-page-size="inviteUserDetailPageConfig.pageSize = $event"
        @up-page-value="inviteUserDetailPageConfig.pageValue = $event" @get-message="getInviteUserDetailList($event)">
      </div-table>
    </el-dialog>
  </div>
</template>

<script>
import divTable from '../components/div-table.vue'

const columns1 = [
  {
    key: 'userName',
    label: '账号名称',
    minWidth: '150px'
  },
  {
    key: 'email',
    label: '邮箱名称',
    minWidth: '150px'
  },
  {
    key: 'operateTime',
    label: '活跃时间',
    minWidth: '250px'
  },
  {
    key: 'createTime',
    label: '注册时间',
    minWidth: '250px'
  }
]

const columns2 = [
  {
    key: 'userName',
    label: '用户名',
    minWidth: '150px'
  },
  {
    key: 'count',
    label: '数量',
    minWidth: '250px'
  },
  {
    key: 'createTime',
    label: '创建时间',
    minWidth: '250px'
  },
  {
    label: `操作`,
    key: `operate`,
    slot: `operate`,
    width: "120px",
  },
]

const columns3 = [
  {
    key: 'userName',
    label: '账号名称',
    minWidth: '150px'
  },
  {
    key: 'email',
    label: '邮箱名称',
    minWidth: '150px'
  },
  {
    key: 'source',
    label: '来源',
  },
  {
    key: 'createTime',
    label: '注册时间',
    minWidth: '250px'
  }
]

const columns = [
  {
    key: 'userName',
    label: '用户名',
    minWidth: '150px'
  },
  {
    key: 'leftAmount',
    label: '余额($)',
    minWidth: '150px'
  },
  {
    key: 'totalAmount',
    label: '总金额($)',
    minWidth: '150px'
  },
  {
    key: 'totalCount',
    label: '窗口数量',
    minWidth: '150px'
  },
  {
    key: 'createTime',
    label: '注册时间',
    minWidth: '250px'
  }
]

export default {
  name: 'PanelRanking',
  components: {
    divTable,
  },
  props: {
    data: {
      type: Object,
      default: null
    }
  },
  computed: {
    tableList ({ data }) {
      return [
        {
          title: '创建窗口前20',
          columns,
          list: data?.create_win_top_user_list || []
        },
        {
          title: '已打开窗口前20',
          columns,
          list: data?.open_win_top_user_list || []
        },
        {
          title: '今日打开次数前20名',
          columns: columns.map(item => ({ ...item, label: item.key === 'totalCount' ? '打开次数' : item.label })),
          list: data?.open_times_top_user_list || []
        }
      ]
    }
  },
  data () {
    return {
      columns1,
      columns2,
      columns3,
      fromAdmin: 0,
      dialogTableVisible: false,
      userList: [],
      inviteUserList: [],
      inviteUserDetailList: [],
      pageSize: 15,
      pageSizeList: [3, 15, 200, 300, 400],
      pageValue: 1,
      total: 0,
      inviteUserPageConfig: {
        pageSize: 15,
        pageSizeList: [3, 15, 200, 300, 400],
        pageValue: 1,
        total: 0,
      },
      inviteUserDetailPageConfig: {
        pageSize: 15,
        pageSizeList: [3, 15, 200, 300, 400],
        pageValue: 1,
        total: 0,
      },
    }
  },
  mounted () {
    this.getMessage();
    this.getInviteUserList();
  },
  methods: {
    async getMessage (boole = false) {
      if (boole) {
        this.pageValue = 1;
      }
      this.loading = true;
      const {
        code,
        data: { rows, total },
        msg,
      } = await this.$apis
        .get(`/admin_user_today_active_list`, {
          page_index: this.pageValue,
          page_size: this.pageSize,
          ...this.parmes,
        })
        .catch((e) => ({
          code: 1,
        }))
        .finally(() => {
          this.loading = false;
        });
      if (code == 0) {
        this.userList = rows;
        this.total = total;
      } else {
        this.$message.error(msg);
      }
    },

    async getInviteUserList(boole = false) {
      if (boole) {
        this.inviteUserPageConfig.pageValue = 1;
      }
      this.loading = true;
      const {
        code,
        data: { rows, total },
        msg,
      } = await this.$apis
        .get(`/admin_get_add_invite_people_list`, {
          page_index: this.inviteUserPageConfig.pageValue,
          page_size: this.inviteUserPageConfig.pageSize,
          ...this.parmes,
        })
        .catch((e) => ({
          code: 1,
        }))
        .finally(() => {
          this.loading = false;
        });
      if (code == 0) {
        this.inviteUserList = rows;
        this.inviteUserPageConfig.total = total;
      } else {
        this.$message.error(msg);
      }
    },

    async getInviteUserDetailList(boole = false) {
      if (boole) {
        this.inviteUserDetailPageConfig.pageValue = 1;
      }
      this.loading = true;
      const {
        code,
        data: { rows, total },
        msg,
      } = await this.$apis
        .get(`/admin_invite_people_detailed_list`, {
          page_index: this.inviteUserDetailPageConfig.pageValue,
          page_size: this.inviteUserDetailPageConfig.pageSize,
          fromAdmin: this.fromAdmin,
          ...this.parmes,
        })
        .catch((e) => ({
          code: 1,
        }))
        .finally(() => {
          this.loading = false;
        });
      if (code == 0) {
        this.inviteUserDetailList = rows;
        this.inviteUserDetailPageConfig.total = total;
      } else {
        this.$message.error(msg);
      }
    },
    viewInviteUser(row) {
      this.fromAdmin = row.fromAdmin;
      this.dialogTableVisible = true;
      this.getInviteUserDetailList();
    }
  }
}
</script>

<style lang="scss" scoped>
.table {
  margin-bottom: 15px;

  &-title {
    margin-bottom: 15px;
    font-size: 17px;
    font-weight: bold;
    color: #36A3F7;
  }
}
</style>
