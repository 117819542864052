<template>
  <div class="home pages-store">
    <div class="flex-sw-center" style="height: 50px; width: 100%">
      <div class="flex-start-center">
        <el-input placeholder="用户名" v-model.trim="parmes.username" style="width: 180px; margin-right: 15px" @keydown.enter.native="getMessage()"></el-input>
        <el-input placeholder="空间名" v-model.trim="parmes.workspaceName" style="width: 180px; margin-right: 15px" @keydown.enter.native="getMessage()"></el-input>
        <el-input placeholder="IP" v-model.trim="parmes.ip" style="width: 180px; margin-right: 15px" @keydown.enter.native="getMessage()"></el-input>
        <el-button class="h30" style="margin-left: 15px" type="success" size="mini" icon="el-icon-search" @click="getMessage(true)">
          查询
        </el-button>
        <el-button class="h30" type="warning" size="mini" icon="el-icon-refresh" @click="
          reSetParmes();
        getMessage(true);
        ">
          重置
        </el-button>
      </div>

      <div class="flex-start-center" style="margin-left: 20px">
        <el-button type="primary" size="mini" icon="el-icon-plus" @click="
          fromInit();
        from_type = `add`;
        dialog = true;
        ">
          新增
        </el-button>
      </div>
    </div>

    <div-table :loading="loading" :fromList="showPageList(fromList, formShowList)" :fromData="fromData" :pageSize="pageSize" :pageValue="pageValue" :pageSizeList="pageSizeList" :total="total"
      @up-page-size="pageSize = $event" @up-page-value="pageValue = $event" @get-message="getMessage($event)">
      <template v-slot:proxyUser="{ data }">
        <p class="openingTime">
          {{ `${data.rows.proxyUserName}:${data.rows.proxyPassword}` }}
        </p>
      </template>
      <template v-slot:time="{ data }">
        <p class="openingTime">{{ data.rows.expireTime }}</p>
      </template>
      <template v-slot:workspace="{ data }">
        <p>ID: {{ data.rows.workspaceId }}</p>
        <p> {{ data.rows.workspaceName }}</p>
      </template>
      <template v-slot:inputType="{ data }">
        {{ data.rows.inputType == 1 ? "代理资源" : "窗口填写" }}
      </template>
      <template v-slot:operate="{ data }">
        <div :class="data.rows.source != 'platform'
          ? 'operate flex-sw-center'
          : 'operate flex-center-center'
          ">
          <el-link v-show="data.rows.source != 'platform'" :underline="false" type="warning" @click="changeList(data.rows, 'edit')">编辑</el-link>

          <el-link v-show="data.rows.source != 'platform'" :underline="false" type="danger" @click="changeList(data.rows, 'del')">删除</el-link>
          <el-link v-show="!data.rows.iconLoading" :underline="false" type="primary" :style="data.rows.checkState == 1
            ? 'color:#2692FF'
            : data.rows.checkState == 2
              ? 'color:#67C23A'
              : 'color:#ff0000'
            " @click="changeList(data.rows, 'check')">{{
              data.rows.checkState == 1
                ? "检测"
                : data.rows.checkState == 2
                  ? "有效"
                  : "失效"
            }}</el-link>
          <i v-show="data.rows.iconLoading" class="el-icon-loading"></i>
        </div>
      </template>
    </div-table>

    <el-drawer title="窗口详情" :visible.sync="drawer" :before-close="handleClose" size="50%">
      <div ref="drawer" v-if="drawer" class="flex-d" style="width: calc(100% - 30px); margin: 0 15px; padding: 5px 10px">
        <el-descriptions title="详细信息" direction="vertical" border>
          <el-descriptions-item v-for="(item, index) in fromList.filter(
            (item) => item.key != 'operate'
          )" :key="index" :label="item.label">
            <span :style="item.trans && item.trans(windowMessage[item.key])">
              <span v-html="item.transValue == undefined ? windowMessage[item.key] || '-' : item.transValue(windowMessage[item.key], windowMessage)"></span>
            </span>
          </el-descriptions-item>
        </el-descriptions>
      </div>
    </el-drawer>
    <el-dialog :title="from_type == 'add' ? '新增' : '编辑'" :visible.sync="dialog" width="50%">
      <el-form lable-postition="right" ref="from" label-width="120px">
        <el-form-item v-for="(value, index) in showPageList(
          dialogList,
          from_type == 'add' ? addList : editList
        )" :key="index" :label="value.label">
          <el-select v-if="value.type == 'select'" style="width: 100%" placeholder="请选择" v-model="from[value.key]" clearable filterable :filter-method="filterMethod">
            <el-option v-for="(item, item_index) in value.key != `country`
              ? value.selectList
              : filtercountryList || []" :key="item_index" :label="item.label" :value="item.value">
              <div v-if="value.key == `country`" style="display: flex; align-items: center">
                <img :src="countryIcon(item)" width="24" height="auto" style="margin-right: 10px" />
                <span>{{ item.label }} {{ item.value }}</span>
              </div>
            </el-option>
          </el-select>
          <el-date-picker v-else-if="value.type == 'date'" v-model="from[value.key]" type="datetime" placeholder="选择日期时间" value-format="yyyy-MM-dd HH:mm:ss">
          </el-date-picker>
          <div class="flex-d" v-else-if="value.type == 'list'">
            <div class="flex-start-center">
              <el-button :disabled="from[value.key] && from[value.key].length > 100" style="width: 29px; flex-shrink: 0" @click="
                from[value.key].push({
                  host: ``,
                  port: ``,
                  proxyUserName: ``,
                  proxyPassword: ``,
                })
                " type="primary" icon="el-icon-plus" circle size="mini"></el-button>
            </div>
            <div class="flex-start-center" v-for="(item, item_index) in from[value.key]" style="margin-top: 10px" :key="item_index">
              <el-input v-model="item.host" placeholder="主机"></el-input>
              <el-input style="margin-left: 10px" v-model="item.port" placeholder="端口"></el-input>
              <el-input style="margin-left: 10px" v-model="item.proxyUserName" placeholder="代理用户名"></el-input>
              <el-input style="margin-left: 10px" v-model="item.proxyPassword" placeholder="代理用户密码"></el-input>

              <el-button @click="from[value.key].splice(item_index, 1)" style="width: 29px; flex-shrink: 0; margin-left: 10px" type="primary" icon="el-icon-delete" circle size="mini"></el-button>
            </div>
          </div>
          <el-input v-else :type="value.type" style="margin-bottom: 10px" v-model="from[value.key]" placeholder="请输入" :disabled="value.disabled"></el-input>
        </el-form-item>
      </el-form>
      <div class="flex-end-center" style="margin-top: 15px">
        <el-button type="primary" @click="submit()"> 提交</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import divTable from "../components/div-table.vue";
export default {
  components: {
    divTable,
  },
  data () {
    return {
      filtercountryList: [],
      selectList: [
        { label: "住宅IP", value: "resi" },
        { label: "机房IP", value: "dc" },
        { label: "ipv6", value: "IPV6" },
      ],
      selectList1: [
        { label: "手工导入", value: "import" },
        { label: "平台购买", value: "platform" },
      ],
      from_type: "",
      from: {},
      parmes: {},
      loading: false,
      fromList: [
        {
          width: "80px",
          label: `ID`,
          key: `id`,
          slot: "id",
          click: (value) => {
            this.drawer = true;
            this.windowMessage = value;
          },
          trans: (value) => {
            return `color: blue;font-weight: bold;cursor: pointer;`;
          },
        },
        {
          label: `ipId`,
          key: `ipId`,
        },
        {
          label: `用户名`,
          key: `userName`,
        },
        {
          label: `空间名`,
          key: `workspaceName`,
          minWidth: "150px",
        },
        {
          label: `添加方式`,
          key: `inputType`,
          slot: "inputType",
          transValue: (value) => {
            return value == 1 ? "代理资源" : "窗口填写";
          },
        },
        {
          label: `网络协议`,
          key: `ipType`,
          transValue: (value) => {
            return (
              this.dialogList
                .find((x) => x.key == "ipType")
                .selectList?.find((z) => z.value == value)?.label || value
            );
          },
        },
        {
          label: `代理服务商`,
          key: `proxyProvider`,
        },
        {
          label: `代理协议`,
          key: `protocol`,
          transValue: (value) => {
            return (
              this.dialogList
                .find((x) => x.key == "protocol")
                .selectList?.find((z) => z.value == value)?.label || value
            );
          },
        },
        {
          label: `国家`,
          key: `country`,
          transValue: (value) => {
            return (
              this.dialogList
                .find((x) => x.key == "country")
                .selectList?.find((z) => z.value == value)?.label || value
            );
          },
        },
        {
          label: `州`,
          key: `state`,
        },
        {
          label: `城市`,
          key: `city`,
        },
        {
          label: `主机信息`,
          key: `host`,
          minWidth: "200px",
          transValue: (_, data) => `${data.host}:${data.port}`,
          slot: 'copy'
        },
        {
          label: `账号信息`,
          key: `proxyUserName`,
          minWidth: "200px",
          transValue: (_, data) => `${data.proxyUserName}:${data.proxyPassword}`,
          slot: 'copy'
        },
        {
          label: `ip时长`,
          key: `proxyTime`,
        },
        {
          label: `刷新URL`,
          key: `refreshUrl`,
        },
        {
          label: `接入点`,
          key: `accessServer`,
        },
        {
          label: `查询渠道`,
          key: `checkChannel`,
        },
        {
          label: `提取链接服务商`,
          key: `extraChannel`,
        },
        {
          label: `每次打开都提取新IP`,
          key: `isExtraNewIp`,
          trans: (val) => {
            return val ? "color:#2692ff;" : "color: red;";
          },
          transValue: (value) => {
            return !value ? "否" : "是";
          },
        },
        {
          label: `提取IP是否校验重复`,
          key: `isRepectCheck`,
          trans: (val) => {
            return val ? "color:#2692ff;" : "color: red;";
          },
          transValue: (value) => {
            return !value ? "否" : "是";
          },
        },
        {
          label: `提取IP链接`,
          key: `extraUrl`,
        },
        {
          label: `代理备注`,
          key: `remark`,
        },
        {
          label: `出口IP`,
          key: `lastIp`,
        },
        {
          label: `出口国家`,
          key: `lastCountry`,
        },
        {
          label: `出口州`,
          key: `lastState`,
        },
        {
          label: `出口城市`,
          key: `lastCity`,
        },
        {
          label: `语言`,
          key: `ipLanguage`,
        },
        {
          label: `时区`,
          key: `timezone`,
        },
        {
          label: `经度`,
          key: `longitude`,
        },
        {
          label: `纬度`,
          key: `latitude`,
        },
        {
          label: `检测状态`,
          key: `checkStatus`,
          trans: (val) => {
            return val ? "color:#2692ff;" : "color: red;";
          },
          transValue: (value) => {
            return value ? "有效" : "无效";
          },
        },
        {
          label: `创建时间`,
          key: `createTime`,
          width: "160px",
        },
        {
          label: `更新时间`,
          key: `updateTime`,
        },
        {
          label: `操作`,
          key: `operate`,
          slot: `operate`,
          width: "120px",
        },
      ],
      formShowList: [
        "id",
        "userName",
        "mainUserName",
        "workspaceName",
        "inputType",
        "source",
        "moduleName",
        "proxyType",
        "proxyNetwork",
        "ipType",
        "protocol",
        "host",
        "port",
        "proxyUserName",
        "proxyPassword",
        "hostPort",
        // "host",
        // "port",
        "proxyUser",
        "expireTime",
        "autoRebuy",
        // "lastIp",
        // "lastCountry",
        // "checkStatus",
        "createTime",
        "operate",
      ],
      fromData: [],
      pageSize: 15,
      pageSizeList: [3, 15, 200, 300, 400],
      pageValue: 1,
      total: 0,
      // 抽屉
      windowMessage: {},
      drawer: false,
      // 弹窗
      dialog: false,
      dialogList: [
        {
          label: `ID`,
          key: `id`,
          type: `text`,
          disabled: true,
        },
        {
          label: `用户名`,
          key: `userName`,
          type: `text`,
        },
        {
          label: `网络协议`,
          key: `ipType`,
          type: `select`,
          selectList: [],
        },
        {
          label: `代理协议`,
          key: `protocol`,
          type: `select`,
          selectList: [],
        },
        {
          label: `国家`,
          key: `country`,
          type: `select`,
          selectList: [],
        },
        {
          label: `代理主机`,
          key: `host`,
          type: `text`,
        },
        {
          label: `代理端口`,
          key: `port`,
          type: `text`,
        },
        {
          label: `代理账号`,
          key: `proxyUserName`,
          type: `text`,
        },
        {
          label: `代理密码`,
          key: `proxyPassword`,
          type: `text`,
        },

        {
          label: `刷新URL`,
          key: `refreshUrl`,
        },
        {
          label: `备注`,
          key: `remark`,
          type: `textarea`,
        },
        {
          label: `来源`,
          key: `source`,
          type: `select`,
          selectList: [],
        },
        {
          label: "ip列表",
          key: "ip_list",
          type: "list",
          defaultValue: [],
        },
      ],
      addList: [
        "userName",
        "proxyType",
        "proxyNetwork",
        "ipType",
        "protocol",
        "country",
        "remark",
        "expireTime",
        "ip_list",
      ],
      editList: [
        `id`,
        `ipType`,
        `moduleName`,
        `proxyNetwork`,
        `proxyType`,
        `protocol`,
        `host`,
        `port`,
        `port`,
        `proxyUserName`,
        `proxyPassword`,
        `refreshUrl`,
        `remark`,
      ],
    };
  },
  created () {
    this.$dicts.getSystemDict();
  },
  async mounted () {
    this.reSetParmes();
    this.getMessage();
    this.getSystemDict();
  },

  watch: {},
  methods: {
    filterMethod (val) {
      this.from.country = val;
      this.filtercountryList = this.dialogList
        .find((x) => x.key == "country")
        .selectList.filter(
          (x) => x.value.indexOf(val) == 0 || x.label.indexOf(val) == 0
        );
    },
    countryIcon ({ value }) {
      return require(`@/assets/country/${value}.svg`);
    },
    //
    putSvg (val) {
      return "@/assets/country/" + val + ".svg";
    },
    getSystemDict (type) {
      let list = [
        "proxyType",
        "ipType",
        "proxyNetwork",
        "protocol",
        "country",
        "source",
      ];
      let arr = JSON.parse(window.localStorage.getItem(`dicts`));
      list.forEach((x) => {
        this.dialogList.forEach((element) => {
          if (element.key == x) {
            if (x == "country") {
              this.filtercountryList = arr[x];
            }
            element.selectList = arr[x];
          }
        });
      });
    },
    showPageList (arr, filterateArr) {
      let newArr = [];
      arr.forEach((item) => {
        filterateArr.indexOf(item.key) != -1 ? newArr.push(item) : "";
      });
      return newArr;
    },
    changeList (obj, type) {
      this.from_type = type;
      if (type == `edit`) {
        this.dialog = true;
        this.from = Object.assign({}, obj);
      } else if (type == `del`) {
        this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.delete(obj);
          })
          .finally(() => {
            this.loading = false;
          });
      } else if (type == `check`) {
        this.check(obj);
      }
    },
    async check (obj) {
      this.fromData.find((x) => x.id == obj.id).iconLoading = true;
      const { code, data, msg } = await this.$apis
        .post(`/admin_check_proxy`, {
          id: obj.id,
        })
        .catch((e) => ({
          code: 1,
        }))
        .finally(() => {
          this.fromData.find((x) => x.id == obj.id).iconLoading = false;
        });
      if (code == 0) {
        this.fromData.find((x) => x.id == obj.id).checkState = 2;
      } else {
        this.fromData.find((x) => x.id == obj.id).checkState = 3;
      }
    },
    async delete (obj) {
      const { code, data, msg } = await this.$apis
        .get(`/admin_delete_proxy`, {
          id: obj.id,
        })
        .catch((e) => ({
          code: 1,
        }))
        .finally(() => {
          this.loading = false;
        });
      if (code == 0) {
        this.$message.success("删除成功");
        this.getMessage();
      } else {
        this.$message.error(msg);
      }
    },
    async submit () {
      const { code, data, msg } = await this.$apis
        .post(
          `${this.from_type == "add" ? "/admin_add_proxy" : "/admin_mdf_proxy"
          }`,
          this.from_type == "add"
            ? { ...this.from }
            : {
              id: this.from.id,
              ipType: this.from.ipType,
              proxyUserName: this.from.proxyUserName,
              proxyType: this.from.proxyType,
              protocol: this.from.protocol,
              host: this.from.host,
              port: this.from.port,
              proxyPassword: this.from.proxyPassword,
              refreshUrl: this.from.refreshUrl,
              remark: this.from.remark,
            }
        )
        .catch((e) => ({
          code: 1,
        }))
        .finally(() => {
          this.loading = false;
        });
      if (code == 0) {
        this.getMessage();
        this.dialog = false;
        this.$message.success("操作成功");
      } else {
        this.$message.error(msg);
      }
    },
    fromInit () {
      let obj = {};
      this.dialogList.forEach((elem) => {
        obj[elem.key] =
          (elem.defaultValue instanceof Function
            ? elem.defaultValue()
            : elem.defaultValue) || ``;
        obj[elem.key].length > 0 ? (obj[elem.key] = []) : "";
      });

      this.from = obj;
    },
    handleClose (done) {
      return done();
    },
    reSetParmes () {
      this.parmes = {
        username: "",
      };
    },
    async getMessage (boole = false) {
      if (boole) {
        this.pageValue = 1;
      }
      this.loading = true;
      const {
        code,
        data: { rows, total },
        msg,
      } = await this.$apis
        .get(`/admin_proxy_list`, {
          page_index: this.pageValue,
          page_size: this.pageSize,
          ...this.parmes,
        })
        .catch((e) => ({
          code: 1,
        }))
        .finally(() => {
          this.loading = false;
        });
      if (code == 0) {
        rows.forEach((item) => {
          item.iconLoading = false;
          item.checkState = 1;
          item.hostPort = `${item.host}:${item.port}`;
        });
        this.fromData = rows;
        this.total = total;
      } else {
        this.$message.error(msg);
      }

      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.home {
  background-color: #fff;
}

::v-deep .el-date-editor.el-input {
  width: 100%;
}

.h30 {
  height: 30px;
}

.openingTime {
  white-space: normal;
  margin: 0;
}
</style>
