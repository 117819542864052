<template>
  <div class="home pages-store">
    <div class="flex-sw-center" style="height: 50px; width: 100%">
      <div class="flex-start-centere">
        <el-input
          placeholder="用户名"
          v-model.trim="parmes.userName"
          clearable
          style="width: 180px; margin-right: 15px"
          @keydown.enter.native="getMessage(true)"
        ></el-input>
        <el-input
          placeholder="空间名"
          v-model.trim="parmes.workspaceName"
          style="width: 180px;"
          clearable
          @keydown.enter.native="getMessage(true)"
        ></el-input>
        <el-button
          class="h30"
          style="margin-left: 15px"
          type="success"
          size="mini"
          icon="el-icon-search"
          @click="getMessage(true)"
        >
          查询
        </el-button>
        <el-button
          class="h30"
          type="warning"
          size="mini"
          icon="el-icon-refresh"
          @click="
            reSetParmes();
            getMessage(true);
          "
        >
          重置
        </el-button>
      </div>

      <div class="flex-start-center" style="margin-left: 20px">

      </div>
    </div>

    <div-table
      :loading="loading"
      :fromList="fromList"
      :fromData="fromData"
      :pageSize="pageSize"
      :pageValue="pageValue"
      :pageSizeList="pageSizeList"
      :total="total"
      @up-page-size="pageSize = $event"
      @up-page-value="pageValue = $event"
      @get-message="getMessage($event)"
    >
      <template v-slot:operate="{ data }">
        <div class="operate flex-center-center">
          <el-link
            :underline="false"
            type="warning"
            @click="changeList(data.rows, 'edit')"
            >编辑</el-link
          >
          <el-link
            :underline="false"
            type="danger"
            @click="changeList(data.rows, 'del')"
            >删除</el-link
          >
        </div>
      </template>
    </div-table>

    <el-dialog
      :title="from_type == 'add' ? '新增' : '编辑'"
      :visible.sync="dialogShow"
      width="30%"
    >
      <el-form lable-postition="right" ref="form" label-width="120px">
        <el-form-item
          v-for="(value, index) in showPageList(
            dialogList,
            from_type == 'add' ? addList : editList
          )"
          :key="index"
          :label="value.label"
        >
          <el-select
            clearable
            v-if="value.type == 'select'"
            v-model="dialogFrom[value.key]"
            @change="changeType($event)"
            placeholder="请选择"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <template v-else-if="value.type == 'radio'">
            <el-radio v-model="dialogFrom[value.key]" :label="true">有效</el-radio>
            <el-radio v-model="dialogFrom[value.key]" :label="false">无效</el-radio>
          </template>
          <el-input
            v-else
            style="margin-bottom: 10px"
            v-model="dialogFrom[value.key]"
            placeholder="请输入"
            :disabled="value.disabled || false"
          ></el-input>
        </el-form-item>
      </el-form>
      <div class="flex-end-center" style="margin-top: 15px">
        <el-button type="primary" @click="submit()"> 提交</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import divTable from "./components/div-table.vue";
const columns = [
  {
    label: "ID",
    key: "id",
    disabled: true,
  },
  {
    label: "用户名",
    key: "userName",
  },
  {
    label: "空间名",
    key: "workspaceName",
  },
  {
    label: "空间图片",
    key: "workspacePicture",
  },
  {
    label: "是否默认空间",
    key: "workspaceDefault",
    transValue: (row) => {
      return row ? "是" : "否";
    },
  },
  {
    label: "窗口使用量",
    key: "useWindowCount",
  },
  {
    label: "窗口总数量",
    key: "maxWindowCount",
  },
  {
    label: "空间状态",
    key: "workspaceStatus",
    transValue: (row) => {
      return row ? "有效" : "无效";
    },
  },
  {
    label: "创建时间",
    key: "createTime",
  },
  {
    label: "修改时间",
    key: "updateTime",
  },
  // {
  //   label: `操作`,
  //   key: `operate`,
  //   slot: `operate`,
  //   width: "120px",
  // },
]
export default {
  components: {
    divTable,
  },
  data() {
    return {
      uploadUrl: "",
      parmes: {},
      loading: false,
      fromList: columns,
      fromData: [],
      pageSize: 15,
      pageSizeList: [3, 15, 200, 300, 400],
      pageValue: 1,
      total: 0,
      from_type: "add",
      dialogShow: false,
      dialogList: [
        {
          label: "ID",
          key: "id",
          disabled: true,
        },
        {
          label: "类型",
          key: "type",
          type: "select",
        },
        {
          label: "编码",
          key: "label",
        },
        {
          label: "名称",
          key: "sysValue",
        },
        {
          label: "排序",
          key: "sort",
        },
        {
          label: "是否有效",
          key: "flag",
          type: "radio",
        },
        {
          label: "版本时间戳",
          key: "version",
          disabled: true,
        },
      ],
      addList: ["type", "typeName", "label", "sysValue", "sort"],
      editList: [
        "id",
        "type",
        "typeName",
        "sysValue",
        "label",
        "sort",
        "flag",
        "version",
      ],
      dialogFrom: {},
      options: [],
    };
  },
  created() {},
  async mounted() {
    this.getMessage();
    this.initSelectList();
  },

  watch: {},
  methods: {
    changeType(val) {
      console.log("🚀 ~ changeType ~ val:", val);
      this.dialogFrom.typeName = this.options.find((x) => x.value == val).label;
    },
    showPageList(arr, filterateArr) {
      let newArr = [];
      arr.forEach((item) => {
        filterateArr.indexOf(item.key) != -1 ? newArr.push(item) : "";
      });
      return newArr;
    },
    async submit() {
      this.loading = true;
      const { code, data, msg } = await this.$apis
        .post(
          `${this.from_type == "add" ? "/admin_add_dict" : "/admin_mdf_dict"}`,
          this.from_type == "add"
            ? { ...this.dialogFrom }
            : {
                id: this.dialogFrom.id,
                type: this.dialogFrom.type,
                typeName: this.dialogFrom.typeName,
                label: this.dialogFrom.label,
                sysValue: this.dialogFrom.sysValue,
                sort: this.dialogFrom.sort,
                flag: this.dialogFrom.flag,
              }
        )
        .catch((err) => ({
          code: 1,
        }))
        .catch((e) => ({
          code: 1,
        }))
        .finally(() => {
          this.loading = false;
        });
      if (code == 0) {
        this.dialogShow = false;
        this.$message.success("操作成功");
        // this.reSetParmes();
        this.getMessage();
      } else {
        this.$message.error(msg);
      }
    },
    delete(row, type) {
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const { code, data, msg } = await this.$apis
            .get("/admin_delete_dict", {
              id: row.id,
            })
            .catch((e) => ({
              code: 1,
            }))
            .finally(() => {
              this.loading = false;
            });
          if (code == 0) {
            this.$message.success("操作成功");
            this.getMessage();
          } else {
            this.$message.error(msg);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async changeList(row, type) {
      if (type == `edit`) {
        this.from_type = "edit";
        this.dialogShow = true;
        this.dialogFrom = Object.assign({}, row);
      } else if (type == "del") {
        this.delete(row, type);
      }
    },
    async initSelectList() {
      const { data, code, msg } = await this.$apis.get(`/admin_dict_type`);
      if (code == 0) {
        this.options = data.map((elem) => ({
          label: elem.typeName,
          value: elem.type,
        }));
      }
    },
    reSetParmes() {
      this.parmes = {};
    },
    fromInit(parmes = {}) {
      let obj = {};

      this.dialogList.forEach((elem) => {
        let { key, label } = elem;

        obj[key] = label || "";
      });
      this.dialogFrom = { flag: true };
    },
    async getMessage(boole = false) {
      if (boole) {
        this.pageValue = 1;
      }
      this.loading = true;
      const {
        code,
        data: { rows, total },
        msg,
      } = await this.$apis
        .get(`/admin_get_workspace_list`, {
          page_index: this.pageValue,
          page_size: this.pageSize,
          ...this.parmes,
        })
        .catch((e) => ({
          code: 1,
        }))
        .finally(() => {
          this.loading = false;
        });
      if (code == 0) {
        // this.fromData = rows.filter((x) => x.status == 1);
        this.fromData = rows;
        this.total = total;
      } else {
        this.$message.error(msg);
      }

      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.home {
  background-color: #fff;
}
.operate {
  justify-content: space-around;
}
.h30 {
  height: 30px;
}
.avatar {
  width: 36px;
}
</style>
