var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "home pages-store" },
    [
      _c(
        "el-descriptions",
        {
          staticClass: "margin-top",
          attrs: {
            title: "系统设置",
            column: 4,
            direction: "vertical",
            border: "",
          },
        },
        [
          _c(
            "el-descriptions-item",
            [
              _c("template", { slot: "label" }, [_vm._v(" 语言 ")]),
              _c(
                "el-select",
                {
                  attrs: { clearable: true, placeholder: "请选择" },
                  model: {
                    value: _vm.form.language,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "language", $$v)
                    },
                    expression: "form.language",
                  },
                },
                _vm._l(_vm.getSystemDict("language"), function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
            ],
            2
          ),
          _c(
            "el-descriptions-item",
            [
              _c("template", { slot: "label" }, [_vm._v(" 时区 ")]),
              _c(
                "el-select",
                {
                  attrs: { clearable: true, placeholder: "请选择" },
                  model: {
                    value: _vm.form.timezone,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "timezone", $$v)
                    },
                    expression: "form.timezone",
                  },
                },
                _vm._l(_vm.getSystemDict("timeZone"), function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
            ],
            2
          ),
          _c(
            "el-descriptions-item",
            [
              _c("template", { slot: "label" }, [_vm._v(" 关闭软件时 ")]),
              _c(
                "el-select",
                {
                  attrs: { clearable: true, placeholder: "关闭软件时" },
                  model: {
                    value: _vm.form.closeSoft,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "closeSoft", $$v)
                    },
                    expression: "form.closeSoft",
                  },
                },
                _vm._l(_vm.closeSoftList, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
            ],
            2
          ),
          _c(
            "el-descriptions-item",
            [
              _c("template", { slot: "label" }, [
                _vm._v(" 自动删除天数前的缓存 "),
              ]),
              _c("el-input", {
                attrs: { placeholder: "请输入删除天数" },
                model: {
                  value: _vm.form.autoDelDays,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "autoDelDays", $$v)
                  },
                  expression: "form.autoDelDays",
                },
              }),
            ],
            2
          ),
          _c(
            "el-descriptions-item",
            [
              _c("template", { slot: "label" }, [
                _vm._v(" 自动删除超过GB的缓存 "),
              ]),
              _c("el-input", {
                attrs: { placeholder: "请输入GB值" },
                model: {
                  value: _vm.form.autoDelSize,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "autoDelSize", $$v)
                  },
                  expression: "form.autoDelSize",
                },
              }),
            ],
            2
          ),
          _c(
            "el-descriptions-item",
            [
              _c("template", { slot: "label" }, [
                _vm._v(" 性能监控触发极限数值 "),
              ]),
              _c("el-input", {
                attrs: { placeholder: "请输入性能监控触发极限数值" },
                model: {
                  value: _vm.form.memoryLimit,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "memoryLimit", $$v)
                  },
                  expression: "form.memoryLimit",
                },
              }),
            ],
            2
          ),
          _c(
            "el-descriptions-item",
            [
              _c("template", { slot: "label" }, [
                _vm._v(" 通过本地打开域名列表 "),
              ]),
              _c("div", { staticClass: "addBlackList" }, [
                _c("i", { on: { click: _vm.addBlackList } }, [_vm._v("+")]),
                _c("span", [
                  _vm._v(
                    "当前数量" + _vm._s(_vm.form.ipBlackList.length) + "/100"
                  ),
                ]),
              ]),
              _c(
                "div",
                { staticClass: "blackList" },
                _vm._l(_vm.form.ipBlackList, function (item, index) {
                  return _c(
                    "div",
                    { key: index, staticClass: "input" },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.form.ipBlackList[index],
                          callback: function ($$v) {
                            _vm.$set(_vm.form.ipBlackList, index, $$v)
                          },
                          expression: "form.ipBlackList[index]",
                        },
                      }),
                      _c("i", {
                        staticClass: "el-icon-delete",
                        on: {
                          click: function ($event) {
                            return _vm.delBlackList(index)
                          },
                        },
                      }),
                    ],
                    1
                  )
                }),
                0
              ),
            ],
            2
          ),
          _c(
            "el-descriptions-item",
            [
              _c("template", { slot: "label" }, [_vm._v(" tab页数量限制 ")]),
              _c("el-input-number", {
                attrs: {
                  min: 1,
                  "controls-position": "right",
                  placeholder: "请输入tab页数量限制",
                },
                model: {
                  value: _vm.form.synTabPages,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "synTabPages", $$v)
                  },
                  expression: "form.synTabPages",
                },
              }),
            ],
            2
          ),
          _c(
            "el-descriptions-item",
            [
              _c("template", { slot: "label" }, [_vm._v(" IP检测地址 ")]),
              _c(
                "el-select",
                {
                  attrs: { clearable: true, placeholder: "IP检测地址" },
                  model: {
                    value: _vm.form.ipChecker,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "ipChecker", $$v)
                    },
                    expression: "form.ipChecker",
                  },
                },
                _vm._l(_vm.getSystemDict("checkChannel"), function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
            ],
            2
          ),
          _c(
            "el-descriptions-item",
            [
              _c("template", { slot: "label" }, [_vm._v(" 系统代理 ")]),
              _c("el-switch", {
                attrs: {
                  "active-text": "开启",
                  "inactive-text": "关闭",
                  "active-value": 1,
                  "inactive-value": 0,
                },
                model: {
                  value: _vm.form.systemProxySwitch,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "systemProxySwitch", $$v)
                  },
                  expression: "form.systemProxySwitch",
                },
              }),
            ],
            2
          ),
          _c(
            "el-descriptions-item",
            [
              _c("template", { slot: "label" }, [_vm._v(" v2ray兼容 ")]),
              _c("el-switch", {
                attrs: {
                  "active-text": "开启",
                  "inactive-text": "关闭",
                  "active-value": 1,
                  "inactive-value": 0,
                },
                model: {
                  value: _vm.form.v2raySwitch,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "v2raySwitch", $$v)
                  },
                  expression: "form.v2raySwitch",
                },
              }),
            ],
            2
          ),
          _c(
            "el-descriptions-item",
            [
              _c("template", { slot: "label" }, [_vm._v(" app风格 ")]),
              _c(
                "el-select",
                {
                  attrs: { placeholder: "app 风格" },
                  model: {
                    value: _vm.form.appearance,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "appearance", $$v)
                    },
                    expression: "form.appearance",
                  },
                },
                _vm._l(_vm.appearanceList, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
            ],
            2
          ),
          _c(
            "el-descriptions-item",
            [
              _c("template", { slot: "label" }, [_vm._v(" UA严格模式 ")]),
              _c("el-switch", {
                attrs: {
                  "active-text": "开启",
                  "inactive-text": "关闭",
                  "active-value": 1,
                  "inactive-value": 0,
                },
                model: {
                  value: _vm.form.uaModeSwitch,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "uaModeSwitch", $$v)
                  },
                  expression: "form.uaModeSwitch",
                },
              }),
            ],
            2
          ),
          _c(
            "el-descriptions-item",
            [
              _c("template", { slot: "label" }, [_vm._v(" 域名禁止访问配置 ")]),
              _c("el-input", {
                attrs: { placeholder: "多个域名以逗号分割" },
                model: {
                  value: _vm.form.blockDomainList,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "blockDomainList", $$v)
                  },
                  expression: "form.blockDomainList",
                },
              }),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "submit" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", icon: "el-icon-edit" },
              on: { click: _vm.submit },
            },
            [_vm._v(" 修改 ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }