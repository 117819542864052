<template>
  <div class="home pages-store">
    <div class="flex-sw-center" style="height: 50px; width: 100%">
      <div class="flex-start-centere">
        <el-input placeholder="用户名" v-model.trim="parmes.userName" style="width: 180px; margin-right: 15px" @keydown.enter.native="getMessage()"></el-input>
        <el-input placeholder="空间名" v-model.trim="parmes.workspaceName" style="width: 180px; margin-right: 15px" @keydown.enter.native="getMessage()"></el-input>
        <el-input placeholder="扩展名" v-model.trim="parmes.name" style="width: 180px; margin-right: 15px" @keydown.enter.native="getMessage()"></el-input>
        <el-input placeholder="扩展目录" v-model.trim="parmes.pluginDir" style="width: 280px; margin-right: 15px" @keydown.enter.native="getMessage()"></el-input>
        <el-select clearable v-model="parmes.source" placeholder="扩展来源" @change="getMessage()">
          <el-option v-for="item in sources" :key="item.label" :label="item.label" :value="item.value"></el-option>
        </el-select>
        <el-button class="h30" style="margin-left: 15px" type="success" size="mini" icon="el-icon-search" @click="getMessage(true)">
          查询
        </el-button>
        <el-button class="h30" type="warning" size="mini" icon="el-icon-refresh" @click="
          reSetParmes();
        getMessage(true);
        ">
          重置
        </el-button>
      </div>

      <!-- <div class="flex-start-center" style="margin-left: 20px">
        <el-button
          class="h30"
          type="primary"
          size="mini"
          icon="el-icon-plus"
          @click="
            fromInit();
            from_type = `add`;
            dialogShow = true;
          "
        >
          新增
        </el-button>
      </div> -->
    </div>

    <div-table :loading="loading" :fromList="tableColumns" :fromData="fromData" :pageSize="pageSize" :pageValue="pageValue" :pageSizeList="pageSizeList" :total="total"
      @up-page-size="pageSize = $event" @up-page-value="pageValue = $event" @get-message="getMessage($event)">
      <template v-slot:pluginIcon="{ data }">
        <el-image @click="copyLink(data.rows.pluginIcon)" style="width: 30%; height: auto; cursor: pointer" :src="data.rows.pluginIcon" fit="cover" title="点击复制链接"></el-image>
      </template>


      <template v-slot:operate="{ data }">
        <div class="operate flex-center-center">
          <el-link :underline="false" type="danger" @click="changeList(data.rows, 'del')">删除</el-link>
        </div>
      </template>
    </div-table>

    <el-dialog :title="from_type == 'add' ? '新增' : '编辑'" :visible.sync="dialogShow" width="50%">
      <el-form lable-postition="right" ref="form" label-width="120px">
        <el-form-item v-for="(value, index) in showPageList(
          dialogList,
          from_type == 'add' ? addList : editList
        )" :key="index" :label="value.label">
          <el-select v-if="value.key == 'categoryName'" v-model="dialogFrom.categoryName" multiple placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
          <el-upload v-if="value.type == 'upload'" class="avatar-uploader" :action="uploadUrl" :show-file-list="false" :limit="1" :on-change="beforeAvatarUpload" :before-upload="beforeUpload"
            :on-success="handleSuccess" :on-error="handleError">
            <img v-if="dialogFrom.pluginIcon" :src="dialogFrom.pluginIcon" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
          <el-input v-else style="margin-bottom: 10px" v-model="dialogFrom[value.key]" placeholder="请输入" :disabled="value.disabled || false"></el-input>
        </el-form-item>
      </el-form>
      <div class="flex-end-center" style="margin-top: 15px">
        <el-button type="primary" @click="submit()"> 提交</el-button>
      </div>
    </el-dialog>
    <div-table-detail title="窗口详情" :list="fromList" :data="tableData" :visible.sync="tableDetailShow">
      <template #rowWrap="{ data }">
        <span>{{ `${data.createTime} / ${data.updateTime}` }}</span>
      </template>
    </div-table-detail>
  </div>
</template>

<script>
import divTable from "../components/div-table.vue";
import divTableDetail from '../components/div-table-detail.vue';
import { clipboardText } from '@/utils';
import OSS from 'ali-oss';
const sources = [
  {
    label: '扩展推荐',
    value: 'sys'
  },
  {
    label: '本地上传',
    value: 'local'
  },
  {
    label: 'chrome应用商店',
    value: 'store'
  }
]
export default {
  components: {
    divTable,
    divTableDetail
  },
  data () {
    return {
      uploadUrl: "",
      parmes: {},
      loading: false,
      sources,
      tableDetailShow: false,
      tableData: null,
      fromList: [
        {
          label: `ID`,
          key: `id`,
          width: `60px`,
          slot: "id",
          tableView: true,
          click: (value) => {
            this.tableDetailShow = true;
            this.tableData = value;
          },
          trans: () => {
            return `color: blue; font-weight: bold; cursor: pointer;`;
          },
        },
        {
          label: `用户名`,
          key: `userName`,
          tableView: true,
        },
        {
          label: `空间名`,
          key: `workspaceName`,
          tableView: true,
          minWidth: '150px',
        },
        {
          label: `扩展来源`,
          key: `source`,
          tableView: true,
          transValue: (val) => sources.find(item => item.value == val)?.label || '未知',
        },
        {
          label: `扩展目录`,
          key: `pluginDir`,
          slot: "copy",
          minWidth: '180px',
          tableView: true,
        },
        {
          label: `扩展名称`,
          key: `pluginName`,
          slot: "copy",
          tableView: true,
          minWidth: '150px',
        },
        {
          label: `扩展缩略图`,
          key: `pluginIcon`,
          slot: "pluginIcon",
          tableView: true,
        },
        {
          label: `扩展路由`,
          key: `pluginRoute`,
          slot: "copy",
        },
        {
          label: `扩展版本`,
          key: `pluginVersion`,
          tableView: true,
        },
        {
          label: `扩展描述`,
          key: `pluginDesc`,
          slot: "copy",
        },
        {
          label: `提供方`,
          key: `provider`,
          slot: "copy",
        },
        {
          label: `创建时间`,
          key: `createTime`,
          width: '158px',
          tableView: true,
        },
        {
          label: `操作`,
          key: `operate`,
          slot: `operate`,
          width: "100px",
          tableOnly: true,
        },
        {
          label: `固定方式`,
          key: `fixedPlugin`,
          transValue: (val) => {
            if (val == 'customize') {
              return "自定义";
            } else if (val == 'pin') {
              return "始终固定到工具栏";
            } else if (val == 'unpin') {
              return "始终不固定到工具栏";
            }
          },
        },
        {
          label: `扩展更新状态`,
          key: `pluginUpdateState`,
          transValue: (val) => {
            return val == 1 ? "已更新" : "未更新";
          },
        },
        {
          label: `扩展最新版本号`,
          key: `pluginNewVersion`,
        },
        {
          label: `扩展 mainifest key`,
          key: `localKey`,
        },
        {
          label: `扩展归属`,
          key: `pluginSwitchType`,
          transValue: (val) => {
            return val == 2 ? "个人" : "团队";
          },
        },
      ],
      fromData: [],
      pageSize: 15,
      pageSizeList: [3, 15, 200, 300, 400],
      pageValue: 1,
      total: 0,
      from_type: "add",
      dialogShow: false,
      dialogList: [
        {
          label: "ID",
          key: "id",
          disabled: true,
        },
        {
          label: "扩展目录id",
          key: "pluginDir",
        },
        {
          label: "扩展来源",
          key: "source",
        },
        {
          label: "扩展名称",
          key: "pluginName",
        },
        {
          label: "扩展缩略图",
          key: "pluginIcon",
          type: "upload",
        },
        {
          label: "扩展路由",
          key: "pluginRoute",
          type: "upload",
        },
        {
          label: "扩展版本",
          key: "pluginVersion",
        },
        {
          label: "扩展描述",
          key: "pluginDesc",
        },
        {
          label: "提供方",
          key: "provider",
        },
        {
          label: "排序",
          key: "sortNum",
        },
        {
          label: "扩展分类",
          key: "categoryName",
        },
      ],
      addList: [
        "pluginDir",
        "pluginName",
        "pluginIcon",
        "pluginRoute",
        "pluginVersion",
        "pluginDesc",
        "provider",
        "sortNum",
        "categoryCode",
      ],
      editList: [
        "id",
        "pluginDir",
        "pluginName",
        "pluginIcon",
        "pluginRoute",
        "pluginVersion",
        "pluginDesc",
        "provider",
      ],
      dialogFrom: {
        categoryName: [],
        pluginIcon: "",
      },
      options: [],
    };
  },
  computed: {
    tableColumns ({ fromList }) {
      // tableView: 显示在表格中
      // tableOnly: 仅在表格中显示
      return fromList.filter((item) => item.tableView || item.tableOnly)
    }
  },
  created () {
    this.$dicts.getSystemDict();
  },
  async mounted () {
    this.getMessage();
    this.initSelectList();
  },

  watch: {},
  methods: {
    beforeAvatarUpload (file, fileLists) {
      console.log("🚀 ~ beforeAvatarUpload ~ file:", file);
      console.log("🚀 ~ beforeAvatarUpload ~ fileLists:", fileLists);
      console.log(URL.createObjectURL(file.raw));
      const reader = new FileReader();
      reader.onload = (e) => {
        console.log("文件内容：", e.target.result);
      };
      reader.readAsDataURL(file.raw); // 读取文件内容
    },
    async initOSS () {
      // this.ossConfig OSS配置信息的对象
      const { region, accessKeyId, accessKeySecret, bucket } = this.ossConfig;
      this.client = new OSS({
        region,
        accessKeyId,
        accessKeySecret,
        bucket,
      });
      this.uploadUrl = `https://${bucket}.${region}.aliyuncs.com`;
    },
    beforeUpload (file) {
      const fileName = `${Date.now()}-${file.name}`;
      this.client.put(fileName, file).then(
        ({ res }) => {
          console.log("upload success: %j", res.statusCode);
        },
        (err) => {
          console.error("upload error: %j", err);
        }
      );
      // 阻止默认上传行为
      return false;
    },
    handleSuccess (response, file, fileList) {
      // 处理上传成功的响应
    },
    handleError (err, file, fileList) {
      // 处理上传失败的错误
    },
    // async uploadFile() {
    //   // 配置你的OSS信息
    //   const client = new OSS({
    //     region: "<Your region>",
    //     accessKeyId: "<Your accessKeyId>",
    //     accessKeySecret: "<Your accessKeySecret>",
    //     bucket: "<Your bucket>",
    //   });

    //   try {
    //     // 上传文件到OSS
    //     const result = await client.put("目标文件名", "本地文件路径");
    //     console.log(result);
    //   } catch (e) {
    //     console.error(e);
    //   }
    // },
    showPageList (arr, filterateArr) {
      let newArr = [];
      arr.forEach((item) => {
        filterateArr.indexOf(item.key) != -1 ? newArr.push(item) : "";
      });
      return newArr;
    },
    async submit () {
      this.loading = true;
      const { code, data, msg } = await this.$apis
        .post(
          `${this.from_type == "add"
            ? "/admin_create_plugin"
            : "/admin_mdf_plugin"
          }`,
          this.from_type == "add"
            ? { ...this.dialogFrom }
            : {
              id: this.dialogFrom.id,
              pluginDir: this.dialogFrom.pluginDir,
              pluginName: this.dialogFrom.pluginName,
              pluginIcon: this.dialogFrom.pluginIcon,
              pluginRoute: this.dialogFrom.pluginRoute,
              pluginVersion: this.dialogFrom.pluginVersion,
              pluginDesc: this.dialogFrom.pluginDesc,
              provider: this.dialogFrom.provider,
              status: this.dialogFrom.status,
            }
        )
        .catch((err) => ({
          code: 1,
        }))
        .catch((e) => ({
          code: 1,
        }))
        .finally(() => {
          this.loading = false;
        });
      if (code == 0) {
        this.from_type = "edit";
        this.dialogShow = false;
        this.$message.success("操作成功");
        this.reSetParmes();
        this.getMessage();
      } else {
        this.$message.error(msg);
      }
    },
    delete (row, type) {
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          this.loading = true;
          const { code, data, msg } = await this.$apis
            .get("/admin_user_plugin_del", {
              id: row.id,
            })
            .catch((e) => ({
              code: 1,
            }))
            .finally(() => {
              this.loading = false;
            });
          if (code == 0) {
            this.$message.success("操作成功");
            this.getMessage();
          } else {
            this.$message.error(msg);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async changeList (row, type) {
      if (type == "del") {
        this.delete(row, type);
      }
    },
    async initSelectList () {
      const { data, code, msg } = await this.$apis.get(
        `/admin_plugin_category`
      );
      if (code == 0) {
        this.options = data.map((elem) => ({
          label: elem.name,
          value: elem.id,
        }));
      }
    },
    copyLink (val) {
      clipboardText(val)
    },
    reSetParmes () {
      this.parmes = {};
    },
    fromInit (parmes = {}) {
      let obj = {};

      this.dialogList.forEach((elem) => {
        let { key, label } = elem;

        obj[key] = label || "";
      });
      this.dialogFrom = {};
    },
    async getMessage (boole = false) {
      if (boole) {
        this.pageValue = 1;
      }
      this.loading = true;
      const {
        code,
        data: { rows, total },
        msg,
      } = await this.$apis
        .get(`/admin_user_plugin_list`, {
          page_index: this.pageValue,
          page_size: this.pageSize,
          ...this.parmes,
        })
        .catch((e) => ({
          code: 1,
        }))
        .finally(() => {
          this.loading = false;
        });
      if (code == 0) {
        // this.fromData = rows.filter((x) => x.status == 1);
        this.fromData = rows;
        this.total = total;
      } else {
        this.$message.error(msg);
      }

      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.home {
  background-color: #fff;
}

.operate {
  justify-content: space-around;
}

.h30 {
  height: 30px;
}

.avatar {
  width: 36px;
}

.rowWrap {
  white-space: normal;
  margin: 0;
  text-align: left;
}
</style>
