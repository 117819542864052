<template>
  <div class="home pages-store">
    <div class="flex-sw-center" style="height: 50px; width: 100%">
      <div class="flex-start-center" style="height: 50px; width: 100%">
        <el-select v-model="parmes.versionType" placeholder="升级类型" clearable style="width: 180px; margin-right: 15px" @change="getMessage(true)">
          <el-option v-for="item in dictList" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
        <el-select v-model="parmes.os" placeholder="操作系统" clearable style="width: 180px; margin-right: 15px" @change="getMessage(true)">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
        <el-select v-model="parmes.osBit" placeholder="os位数" clearable style="width: 180px; margin-right: 15px" @change="getMessage(true)">
          <el-option v-for="item in osBitOptions" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
        <el-select v-model="parmes.cpu" placeholder="cpu架构" clearable style="width: 180px; margin-right: 15px" @change="getMessage(true)">
          <el-option v-for="item in cpuOptions" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
        <el-select v-model="parmes.coreVersion" placeholder="内核版本" clearable style="width: 180px; margin-right: 15px" @change="getMessage(true)">
          <el-option v-for="item in coreVersionOptions" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
        <el-button class="h30" style="margin-left: 15px" type="success" size="mini" icon="el-icon-search" @click="getMessage(true)">
          查询
        </el-button>
        <el-button class="h30" type="warning" size="mini" icon="el-icon-refresh" @click="
          reSetParmes();
        getMessage(true);
        ">
          重置
        </el-button>
      </div>
      <el-button class="h30" style="margin-left: 15px" type="primary" size="mini" icon="el-icon-plus" @click="
        fromInit();
      from_type = `add`;
      dialogShow = true;
      ">
        新增
      </el-button>
    </div>

    <div-table :loading="loading" :fromList="tableList" :fromData="fromData" :pageSize="pageSize" :pageValue="pageValue" :pageSizeList="pageSizeList" :total="total" @up-page-size="pageSize = $event"
      @up-page-value="pageValue = $event" @get-message="getMessage($event)">
      <template v-slot:createTime="{ data }">
        <p class="openingTime">{{ data.rows.createTime }}</p>
      </template>
      <template v-slot:updateTime="{ data }">
        <p class="openingTime">{{ data.rows.updateTime }}</p>
      </template>
      <template #downloadUrl="{ data }">
        <p class="openingTime">{{ data.rows.downloadUrl }}<icon-copy class="copy-icon" :value="data.rows.downloadUrl"></icon-copy></p>

      </template>
      <template v-slot:operate="{ data }">
        <div class="operate flex-sw-center">
          <el-link :underline="false" type="warning" @click="changeList(data.rows, 'edit')">编辑</el-link>
          <el-link :underline="false" type="danger" @click="changeList(data.rows, 'del')">删除</el-link>
        </div>
      </template>
    </div-table>
    <el-dialog :title="from_type == 'edit' ? '编辑' : '新增'" :visible.sync="dialogShow" width="50%" :close-on-click-modal="false" :before-close="handleBeforeClose" @close="handleClose">
      <el-form lable-postition="right" ref="form" label-width="124px" style="max-height: 70vh; overflow-y: auto; padding-right: 15px">
        <el-form-item v-for="value in formList" :key="value.key" :label="value.label" :required="value.required == undefined ? false : true">
          <el-select style="width: 100%" v-if="value.type == 'select'" v-model="dialogFrom[value.key]" placeholder="请选择" :disabled="value.disabled" v-on="value.events">
            <el-option v-for="item in value.options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
          <template v-else-if="dialogShow && value.type == 'WangEditor'">
            <WangEditor v-model="dialogFrom[value.key]" />
          </template>
          <el-date-picker style="width: 100%" v-else-if="
            value.key == 'releaseTime' ||
            value.key == 'createTime' ||
            value.key == 'updateTime'
          " v-model="dialogFrom[value.key]" type="date" placeholder="选择日期时间" value-format="yyyy-MM-dd HH:mm:ss" :disabled="value.disabled">
          </el-date-picker>
          <div v-else-if="value.key == 'forceUpgrade'">
            <el-radio :disabled="value.disabled" v-model="dialogFrom[value.key]" :label="true">是</el-radio>
            <el-radio :disabled="value.disabled" v-model="dialogFrom[value.key]" :label="false">否</el-radio>
          </div>
          <div v-else-if="value.key == 'ipLimit'">
            <el-radio :disabled="value.disabled" v-model="dialogFrom[value.key]" :label="true">启用</el-radio>
            <el-radio :disabled="value.disabled" v-model="dialogFrom[value.key]" :label="false">关闭</el-radio>
          </div>
          <div v-else-if="value.key === 'status'">
            <el-radio :disabled="value.disabled" v-model="dialogFrom[value.key]" :label="true">生效</el-radio>
            <el-radio :disabled="value.disabled" v-model="dialogFrom[value.key]" :label="false">失效</el-radio>
          </div>
          <div v-else-if="value.type == 'upload'">
            <div clsss="upload-demo" style="
                position: relative;
                width: 100%;
                height: 36px;
                text-align: center;
                line-height: 36px;
                color: #2692ff;
                border: 1px dashed #2692ff;
                border-radius: 6px;
                overflow: hidden;
              ">
              <input type="file" :key="inputKey" multiple :disabled="disableUpload" @change="beforeAvatarUpload" :style="{ cursor: disableUpload ? 'not-allowed' : 'pointer' }" style="
                  position: absolute;
                  left: 0;
                  margin: auto;
                  width: 100%;
                  height: 100%;
                  opacity: 0;
                  z-index: 9;
                  cursor: pointer;
                " />
              <span style="position: absolute; right: 0; left: 0">
                点击此处选择文件，系统会自动上传
              </span>
            </div>
            <div class="upload-list" v-if="isShowProgress">
              <div v-for="item in uploadFileList" :key="item.id" class="upload-list-item">
                {{ item.name }}
                <el-progress :percentage="item.progress"></el-progress>
              </div>
            </div>
          </div>
          <el-input v-else :type="value.type ? value.type : 'text'" style="margin-bottom: 10px" v-model="dialogFrom[value.key]" placeholder="请输入" :disabled="value.disabled"
            :rows="value.rows"></el-input>
        </el-form-item>
      </el-form>
      <div class="flex-end-center" style="margin-top: 15px">
        <el-button type="primary" @click="submit()"> 提交</el-button>
      </div>
    </el-dialog>

    <el-drawer title="窗口详情" :visible.sync="drawer" size="50%">
      <div ref="drawer" v-if="drawer" class="flex-d" style="width: calc(100% - 30px); margin: 0 15px; padding: 5px 10px">
        <el-descriptions title="详细信息" direction="vertical" border>
          <el-descriptions-item v-for="(item, index) in fromList.filter((item) => item.key != 'operate')" :key="index" :label="item.label">
            <span :style="item.trans && item.trans(windowMessage[item.key])">
              {{
                item.transValue == undefined
                  ? windowMessage[item.key] || "-"
                  : item.transValue(windowMessage[item.key], windowMessage)
              }}
            </span>
          </el-descriptions-item>
        </el-descriptions>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import iconCopy from "@/views/huoshan/components/icon-copy.vue";
import WangEditor from '@/components/WangEditor/index.vue';

import { ossConfig } from "../../constants";
import divTable from "./components/div-table.vue";
import { isProd } from "../../constants";
import OSS from 'ali-oss';
const options = [
  { label: "Windows", value: "Windows" },
  { label: "macOS", value: "macOS" },
];

export default {
  components: {
    divTable,
    iconCopy,
    WangEditor
  },
  data () {
    return {
      inputKey: 1,
      drawer: false,
      isShowProgress: false,
      isUploaded: true,
      progress: 0,
      uploadFileList: [],
      options,
      parmes: {},
      loading: false,
      fromList: [
        {
          width: "50px",
          label: `ID`,
          key: `id`,
          slot: "id",
          click: (value) => {
            this.drawer = true;
            this.windowMessage = value;
          },
          trans: (value) => {
            return `color: blue;font-weight: bold;cursor: pointer;`;
          },
        },
        {
          label: `升级类型`,
          key: `versionType`,
          transValue: (val) => {
            return val == "app" ? "app升级" : "内核升级";
          },
        },
        {
          label: `操作系统`,
          key: `os`,
        },
        {
          label: `OS 位数`,
          key: `osBit`,
        },
        {
          label: `OS 最小版本`,
          key: `osVersion`,
        },

        {
          label: `cpu架构`,
          key: `cpu`,
        },

        {
          label: `内核版本`,
          key: `coreVersion`,
        },
        {
          label: `内核真实版本号`,
          key: `coreRealVersion`,
        },
        {
          label: `内置版本号`,
          key: `version`,
        },
        {
          label: `升级标题`,
          key: `upgradeTitle`,
          slot: `copy`,
        },
        {
          label: `升级内容`,
          key: `upgradeContent`,
          slot: `copy`,
        },
        {
          label: `发布时间`,
          key: `releaseTime`,
        },
        {
          label: `下载地址`,
          key: `downloadUrl`,
          minWidth: "340px",
          // slot: `copy`,
          slot: 'downloadUrl'
        },
        {
          label: `更新时间`,
          key: `updateTime`,
          slot: `updateTime`,
          width: `100px`,
        },
        {
          label: `白名单开关`,
          key: `ipLimit`,
          type: "select",
          transValue: (val) => {
            return val ? "启用" : "关闭";
          },
          trans: (val) => {
            return val ? `color:red` : `color:#22B14C;`;
          },
        },
        {
          label: `是否强制升级`,
          key: `forceUpgrade`,
          type: "select",
          transValue: (val) => {
            return val ? "强制升级" : "非强制升级";
          },
        },
       {
          label: `状态`,
          key: `status`,
          type: "select",
          transValue: (val) => {
            return val ? "生效" : "失效";
          },
          trans: (val) => {
            return val ? `color:#22B14C;` : `color:red`;
          },
        },
        {
          label: `创建时间`,
          key: `createTime`,
          slot: `createTime`,
          width: `100px`,
        },

        {
          label: `操作`,
          key: `operate`,
          slot: `operate`,
          width: `120px`,
        },
      ],
      fromData: [],
      pageSize: 15,
      pageSizeList: [3, 15, 200, 300, 400],
      pageValue: 1,
      total: 0,
      from_type: "add",
      dialogShow: false,
      dialogList: [
        {
          label: "ID",
          key: "id",
          disabled: true,
        },
        {
          label: `升级类型`,
          key: `versionType`,
          type: "select",
          options: [],
          required: true,
        },
        {
          label: `操作系统`,
          key: `os`,
          type: "select",
          options: [
            { label: "Windows", value: "Windows" },
            { label: "macOS", value: "macOS" },
          ],
          required: true,
        },
        {
          label: `OS 最小版本`,
          key: `osVersion`,
          type: "select",
          options: [
            { label: "10", value: "10" },
            { label: "8.1", value: "8.1" },
            { label: "8", value: "8" },
            { label: "7", value: "7" },
          ],
          required: true,
          events: {
            change: (val) => {
              this.$forceUpdate();
            },
          },
        },
        {
          label: `OS 位数`,
          key: `osBit`,
          type: "select",
          required: true,
          options: [
            { label: "32位", value: "32" },
            { label: "64位", value: "64" },
          ],
          events: {
            change: (val) => {
              this.$forceUpdate();
            },
          },
        },
        {
          label: `cpu架构`,
          key: `cpu`,
          type: "select",
          options: [
            { label: "intel", value: "intel" },
            { label: "apple", value: "apple" },
          ],
          required: true,
        },
        {
          label: `内核版本`,
          key: `coreVersion`,
          type: "select",
          options: [],
          required: true,
        },
        {
          label: `内核真实版本号`,
          key: `coreRealVersion`,
        },
        {
          label: `内置版本号`,
          key: `version`,
          required: true,
        },
        {
          label: `升级标题`,
          key: `upgradeTitle`,
        },
        {
          label: `升级内容(中文)`,
          key: `upgradeContent`,
          type: "WangEditor",
        },
        {
          label: `升级内容(英文)`,
          key: `upgradeContentEn`,
          type: "WangEditor",
        },
        {
          label: `升级内容(俄文)`,
          key: `upgradeContentRu`,
          type: "WangEditor",
        },
        {
          label: `文件上传`,
          key: `upLoad`,
          type: "upload",
        },
        {
          label: `下载地址`,
          key: `downloadUrl`,
          required: true,
        },
        {
          label: `白名单开关`,
          key: `ipLimit`,
        },
        {
          label: `是否强制升级`,
          key: `forceUpgrade`,
          required: true,
        },
        {
          label: `状态`,
          key: `status`,
        },
        {
          label: `创建时间`,
          key: `createTime`,
        },
        {
          label: `更新时间`,
          key: `updateTime`,
        },
      ],
      addList: [
        "os",
        "osVersion",
        "osBit",
        "cpu",
        "versionType",
        "coreVersion",
        "coreRealVersion",
        "version",
        "upgradeTitle",
        "upgradeContent",
        "upgradeContentEn",
        "upgradeContentRu",
        "releaseTime",
        "upLoad",
        "downloadUrl",
        "forceUpgrade",
        "status",
        "ipLimit",
      ],
      editList: [
        "id",
        "os",
        "osVersion",
        "osBit",
        "cpu",
        "versionType",
        "coreVersion",
        "coreRealVersion",
        "version",
        "upgradeTitle",
        "upgradeContent",
        "upgradeContentEn",
        "upgradeContentRu",
        "releaseTime",
        "upLoad",
        "downloadUrl",
        "forceUpgrade",
        "status",
        "ipLimit",
      ],
      dialogFrom: {
        releaseTime: "",
        createTime: "",
        updateTime: "",
        downloadUrl: "",
        forceUpgrade: false,
        status: 1,
        ipLimit: isProd,
      },
      dictList: [],
      ossConfig: {
        ...ossConfig
      },
      client: null,
    };
  },
  computed: {
    tableList () {
      const excludes = [
        "upgradeTitle",
        "upgradeContent",
        "releaseTime",
        "createTime",
      ];

      return this.fromList.filter((n) => !excludes.includes(n.key));
    },
    osBitOptions () {
      if (this.parmes.os === "Windows") {
        return [
          { label: "32位", value: "32" },
          { label: "64位", value: "64" },
        ];
      } else {
        return [{ label: "64位", value: "64" }];
      }
    },
    cpuOptions () {
      if (this.parmes.os !== "Windows") {
        return [
          { label: "intel", value: "intel" },
          { label: "apple", value: "apple" },
        ];
      } else {
        return [];
      }
    },
    coreVersionOptions () {
      return this.dialogList.find((item) => item.key === "coreVersion").options;
    },
    formList () {
      const excludes = ["osVersion", "coreVersion", "coreRealVersion"];
      const filterateArr = this.from_type == "add" ? this.addList : this.editList;

      return this.dialogList.filter((item) => {
        if (this.dialogFrom.versionType === "app" && excludes.includes(item.key)) {
          return false;
        }
        if (item.key === "cpu" && this.dialogFrom.os !== "macOS") {
          return false;
        }

        return filterateArr.includes(item.key);
      });
    },
    disableUpload () {
      if (this.dialogFrom.versionType === "app") {
        if (!this.dialogFrom.os || !this.dialogFrom.version) {
          return true;
        }
      } else if (this.dialogFrom.versionType === "core") {
        if (
          !this.dialogFrom.os ||
          !this.dialogFrom.osVersion ||
          !this.dialogFrom.coreVersion ||
          !this.dialogFrom.version
        ) {
          return true;
        }
      } else {
        return true;
      }
    },
  },
  watch: {
    "dialogFrom.os": {
      handler (val) {
        const osVersionItem = this.dialogList.find(
          (item) => item.key === "osVersion"
        );
        const osBitItem = this.dialogList.find((item) => item.key === "osBit");
        if (!osVersionItem || !osBitItem) {
          return;
        }
        if (val === "Windows") {
          osVersionItem.options = [
            { label: "10", value: "10" },
            { label: "8.1", value: "8.1" },
            { label: "8", value: "8" },
            { label: "7", value: "7" },
          ];
          osBitItem.options = [
            { label: "32位", value: "32" },
            { label: "64位", value: "64" },
          ];
          this.dialogFrom.osVersion = osVersionItem.options.some(n => n.value === this.dialogFrom.osVersion) ? this.dialogFrom.osVersion : "";
          this.dialogFrom.osBit = osBitItem.options.some(n => n.value === this.dialogFrom.osBit) ? this.dialogFrom.osBit : "";;
        } else if (val === "macOS") {
          osVersionItem.options = [
            { label: "macOS Catalina 10.15 或更高版本", value: "10.15" },
          ];
          osBitItem.options = [{ label: "64位", value: "64" }];
          this.dialogFrom.osVersion = "10.15";
          this.dialogFrom.osBit = "64";
        }
      },
      immediate: true,
    },
  },
  created () {
    this.getDict();
    this.$dicts.getSystemDict();
  },
  async mounted () {
    this.getMessage();
    this.getSystemDict();
    this.initOSS();
    console.log('dialogFrom', this.dialogFrom)
  },

  methods: {
    async beforeAvatarUpload (files, fileLists) {
      await this.initOSS();
      setTimeout(() => {
        this.inputKey++;
      });
      console.log("this.dialogFrom", this.dialogFrom);
      if (this.dialogFrom.versionType === "app") {
        if (!this.dialogFrom.os || !this.dialogFrom.version) {
          return this.$message.error("操作系统或内置版本号不能为空");
        }
      } else if (this.dialogFrom.versionType === "core") {
        if (
          !this.dialogFrom.os ||
          !this.dialogFrom.osVersion ||
          !this.dialogFrom.coreVersion ||
          !this.dialogFrom.version
        ) {
          return this.$message.error(
            "操作系统、OS 最小版本要求、内核版本或内置版本号不能为空"
          );
        }
      } else {
        return this.$message.error("请选择升级类型");
      }
      this.isShowProgress = true;
      this.progress = 0;
      let formObj = this.dialogFrom;
      // 获取文件内容信息
      files = files.target.files;
      console.log("🚀 ~ beforeAvatarUpload ~ files:", files);

      for (const file of files) {
        try {
          let saveUrl = "";
          const fileName = file.name;
          if (this.dialogFrom.versionType == "app") {
            if (this.dialogFrom.os === "Windows") {
              saveUrl = `/public/package/app/Windows/${this.dialogFrom.osBit}/${this.dialogFrom.version}/${fileName}`;
            } else if (this.dialogFrom.os === "macOS") {
              saveUrl = `/public/package/app/macOS/${this.dialogFrom.cpu}/${this.dialogFrom.version}/${fileName}`;
            }
            // saveUrl = `public/package/${formObj.versionType}/${
            //   map[formObj.os]
            // }/${files[0].webkitRelativePath.split("/")[0] || files[0].name.split(".")[0]}.zip`;
          } else {
            if (this.dialogFrom.os === "Windows") {
              saveUrl = `/public/package/core/Windows/${this.dialogFrom.osBit}/${formObj.coreVersion}/${formObj.version}/${fileName}`;
            } else if (this.dialogFrom.os === "macOS") {
              saveUrl = `/public/package/core/macOS/${formObj.cpu}/${formObj.coreVersion}/${formObj.version}/${fileName}`;
            }
            // saveUrl = `public/package/${formObj.versionType}/${
            //   map[formObj.os]
            // }/${formObj.coreVersion}/${formObj.version}/${
            //   formObj.coreVersion
            // }.zip`;
          }
          this.uploadZipFile(saveUrl, file);
        } catch (error) {
          console.error("Error compressing files:", error);
        }
      }
    },
    async uploadZipFile (name, file) {
      const id = Math.random().toString(36).substr(2, 9);
      this.uploadFileList.push({
        id,
        name: file.name,
        progress: 0,
      });
      const target = this.uploadFileList.find((item) => item.id === id);
      try {
        // 上传文件到OSS
        this.client
          .multipartUpload(name, file, {
            // 获取分片上传进度、断点和返回值。
            progress: (p, cpt, res) => {
              target.progress = Math.ceil(p * 100);
              // 打印上传进度
              // console.log('progress:', p);
            },
          })
          .then(
            ({ res }) => {
              console.log("🚀 ~ uploadZipFile ~ res:", res);
              // 文件上传成功
              this.$message.success("上传文件成功");
              if (
                (this.dialogFrom.versionType === "app" && file.name.endsWith(".exe")) ||
                file.name.endsWith(".dmg")
              ) {
                this.dialogFrom.downloadUrl = res.requestUrls[0]?.split("?")[0];
              } else {
                this.dialogFrom.downloadUrl = res.requestUrls[0]?.split("?")[0];
              }
              console.log("🚀 ~ beforeAvatarUpload ~ res:", res);
            },
            (err) => {
              this.$message.error(err);
              console.error("upload error: %j", err);
            }
          );
      } catch (e) {
        console.error(e);
      }
    },
    updataOssConfig () {
      if (new Date(this.ossConfig.expiration).getTime() < new Date().getTime()) {
        this.initOSS();
      }
    },
    async initOSS () {
      const { data, code, msg } = await this.$apis.get("/admin_get_oss_credential");

      if (code == 0) {
        this.ossConfig = { ...this.ossConfig, ...data };
        const {
          region,
          accessKeyId,
          accessKeySecret,
          bucket,
          securityToken,
        } = this.ossConfig;
        this.client = new OSS({
          region,
          accessKeyId,
          accessKeySecret,
          bucket,
          stsToken: securityToken,
        });
        this.uploadUrl = `https://${bucket}.${region}.aliyuncs.com`;
      }
    },
    handleBeforeClose (done) {
      if (this.uploadFileList.every((n) => n.progress === 100)) {
        return done();
      }
      this.$confirm("文件尚未上传完成, 是否关闭?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        done();
      });
    },
    handleClose () {
      this.isShowProgress = false;
      this.uploadFileList = [];
    },
    async changeList (obj, type) {
      console.log("🚀 ~ changeList ~ obj:", obj);
      this.updataOssConfig();
      if (type == `edit`) {
        this.from_type = type;
        this.dialogShow = true;
        this.dialogFrom = Object.assign({}, obj);
      } else if (type == `del`) {
        this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.delete(obj);
          })
          .finally(() => {
            this.loading = false;
          });
      } else if (type == `copyRow`) {
        const { code, data, msg } = await this.$apis
          .post(`/admin_copy_version`, {
            id: obj.id,
          })
          .catch((e) => ({
            code: 1,
          }));
        if (code == 0) {
          this.$message.success("复制成功");
        } else {
          this.$message.error(msg);
        }
      }
    },
    async delete (obj) {
      const { code, data, msg } = await this.$apis
        .get(`/admin_del_version`, {
          id: obj.id,
        })
        .catch((e) => ({
          code: 1,
        }));
      if (code == 0) {
        this.$message.success("删除成功");
        this.getMessage();
      } else {
        this.$message.error(msg);
      }
    },
    reSetParmes () {
      this.parmes = {
        username: "",
      };
    },
    fromInit (parmes = {}) {
      let obj = {};

      this.dialogList.forEach((elem) => {
        let { key, label } = elem;

        obj[key] = label || "";
      });
      this.dialogFrom = {
        releaseTime: "",
        createTime: "",
        updateTime: "",
        downloadUrl: "",
        forceUpgrade: false,
        status: true,
        ipLimit: isProd,
      };
    },
    async submit () {
      this.dialogFrom.releaseTime = this.dialogFrom.releaseTime;
      const { code, data, msg } = await this.$apis
        .post(this.from_type == `add` ? `/admin_add_version` : `/admin_mdf_version`, this.dialogFrom)
        .catch((err) => ({
          code: 1,
        }));
      if (code == 0) {
        this.$message.success(this.from_type == `add` ? "新增成功" : "修改成功");
        this.dialogShow = false;
        this.isShowProgress = false;
        this.progress = 0;
        this.getMessage();
        this.getMessage(true);
        this.dialogFrom = {
          releaseTime: "",
          createTime: "",
          updateTime: "",
          forceUpgrade: false,
        };
      } else {
        this.$message.error(msg);
      }
    },
    async getMessage (boole = false) {
      if (boole) {
        this.pageValue = 1;
      }
      this.loading = true;
      const {
        code,
        data: { rows, total },
        msg,
      } = await this.$apis
        .get(`/admin_version_list`, {
          page_index: this.pageValue,
          page_size: this.pageSize,
          ...this.parmes,
        })
        .catch((e) => ({
          code: 1,
        }))
        .finally(() => {
          this.loading = false;
        });
      if (code == 0) {
        this.fromData = rows;
        this.total = total;
      } else {
        this.$message.error(msg);
      }

      this.loading = false;
    },
    getSystemDict (type) {
      let list = ["coreVersion"];
      let arr = JSON.parse(window.localStorage.getItem(`dicts`));
      list.forEach((x) => {
        this.dialogList.forEach((element) => {
          if (element.key == x) {
            element.options = arr[x];
          }
        });
      });
    },
    async getDict (boole = false) {
      if (boole) {
        this.pageValue = 1;
      }
      this.loading = true;
      const { code, data, msg } = await this.$apis
        .get(`/admin_get_system_dict`, {
          type: "versionType",
        })
        .catch((e) => ({
          code: 1,
        }))
        .finally(() => {
          this.loading = false;
        });
      if (code == 0) {
        this.dictList = data;
        this.dialogList.find((x) => x.key == "versionType").options = data;
      } else {
        this.$message.error(msg);
      }

      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.home {
  background-color: #fff;
}

.h30 {
  height: 30px;
}

.openingTime {
  white-space: normal;
  margin: 0;
}

.upload-list-item {
  padding: 3px 6px;
  background-color: #f5f7fa;
  border-radius: 3px;
  line-height: normal;
  margin-top: 6px;
  white-space: nowrap;
  word-break: keep-all;
  overflow: hidden;
  text-overflow: ellipsis;
}

.copy-icon {
  margin-left: 5px;
}
</style>
