<template>
  <div class="pages-store">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item v-for="(item, index) in paths"
        :key="item.path" >
        <a @click="handleBreadcrumbClick(item.path)">
          {{ item.title }}
        </a>
      </el-breadcrumb-item>
    </el-breadcrumb>
      <el-table
        :data="tableData"
        style="width: 100%; margin-top: 20px;">
        <el-table-column
          prop="directory"
          label="目录" >
          <template slot-scope="scope">
           <a @click="toNext(scope.row)" style="color: #2692ff;">{{ (scope.row.directory || scope.row.file).split('/').filter(Boolean).at(-1) }}</a>
          </template>
        </el-table-column>
        <el-table-column
          prop="type"
          label="文件类型"
          width="180">
        </el-table-column>
        <el-table-column
          prop="size"
          label="大小"
          width="180">
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="getData"
        @current-change="getData"
        :current-page.sync="pagination.page_index"
        :page-sizes="[50,100, 200, 300, 400]"
        :page-size.sync="pagination.page_size"
        layout="sizes, prev, pager, next"
        :total="pagination.total">
    </el-pagination>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        path: '/',
        tableData: [],
        pagination: {
          page_index: 1,
          page_size: 100,
          total: 0
      }
    }
    },
    mounted() {
      this.getData()
    },
    computed: {
      paths() {
        return this.path.split('/').reduce((acc, cur) => {
          if (cur) {
            acc.push({
              title: cur,
              path: `${acc[acc.length - 1]?.path || ''}/${cur}`.replaceAll('//', '/')
            })
          } else if (acc.length === 0) {
            acc.push({
              title: '根目录',
              path: '/'
            })
          }
          return acc
        }, [])
      }
    },
    methods: {
      async getData() {
       const { code, msg, data } = await this.$apis.get('/admin_get_oss_file_list', {
        prefix: `${this.path}/`.replaceAll('//', '/'),
        ...this.pagination
       })
        this.tableData = data.rows
        this.pagination.total = data.total
        console.log(data)
      },
      toNext(row) {
        if (row.type == 'dir') {
          this.path = `/${row.directory}`
          this.getData()
        }
      },
      handleBreadcrumbClick(path) {
        this.path = path
        this.getData()
      }
    }
  }
</script>
