<template>
  <div class="home pages-store">
    <el-descriptions class="margin-top" title="系统设置" :column="4" direction="vertical" border>
      <el-descriptions-item>
        <template slot="label"> 语言 </template>
        <el-select :clearable="true" v-model="form.language" placeholder="请选择">
          <el-option v-for="item in getSystemDict('language')" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label"> 时区 </template>
        <el-select :clearable="true" v-model="form.timezone" placeholder="请选择">
          <el-option v-for="item in getSystemDict('timeZone')" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label"> 关闭软件时 </template>
        <el-select :clearable="true" v-model="form.closeSoft" placeholder="关闭软件时">
          <el-option v-for="item in closeSoftList" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label"> 自动删除天数前的缓存 </template>
        <el-input v-model="form.autoDelDays" placeholder="请输入删除天数"></el-input>
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label"> 自动删除超过GB的缓存 </template>
        <el-input v-model="form.autoDelSize" placeholder="请输入GB值"></el-input>
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label"> 性能监控触发极限数值 </template>
        <el-input v-model="form.memoryLimit" placeholder="请输入性能监控触发极限数值"></el-input>
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label"> 通过本地打开域名列表 </template>
        <div class="addBlackList">
          <i @click="addBlackList">+</i>
          <span>当前数量{{ form.ipBlackList.length }}/100</span>
        </div>
        <div class="blackList">
          <div class="input" v-for="(item, index) in form.ipBlackList" :key="index">
            <el-input v-model="form.ipBlackList[index]" />
            <i @click="delBlackList(index)" class="el-icon-delete"></i>
          </div>
        </div>
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label"> tab页数量限制 </template>
        <el-input-number v-model="form.synTabPages" :min="1" controls-position="right" placeholder="请输入tab页数量限制"></el-input-number>
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label"> IP检测地址 </template>
        <el-select :clearable="true" v-model="form.ipChecker" placeholder="IP检测地址">
          <el-option v-for="item in getSystemDict('checkChannel')" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label"> 系统代理 </template>
        <el-switch
          v-model="form.systemProxySwitch"
          active-text="开启"
          inactive-text="关闭"
          :active-value="1"
          :inactive-value="0"
          >
        </el-switch>
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label"> v2ray兼容 </template>
        <el-switch
          v-model="form.v2raySwitch"
          active-text="开启"
          inactive-text="关闭"
          :active-value="1"
          :inactive-value="0"
          >
        </el-switch>
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label"> app风格 </template>
        <el-select v-model="form.appearance" placeholder="app 风格">
          <el-option v-for="item in appearanceList" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label"> UA严格模式 </template>
        <el-switch
          v-model="form.uaModeSwitch"
          active-text="开启"
          inactive-text="关闭"
          :active-value="1"
          :inactive-value="0"
          >
        </el-switch>
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label"> 域名禁止访问配置 </template>
        <el-input v-model="form.blockDomainList" placeholder="多个域名以逗号分割"></el-input>
      </el-descriptions-item>
    </el-descriptions>
    <div class="submit">
      <el-button type="primary" icon="el-icon-edit" @click="submit">
        修改
      </el-button>
    </div>
  </div>
</template>

<script>
import divTable from "./components/div-table.vue";
export default {
  components: {
    divTable,
  },
  data () {
    return {
      loading: false,
      fromData: [],
      pageSize: 15,
      pageSizeList: [3, 15, 200, 300, 400],
      pageValue: 1,
      total: 0,
      closeSoftList: [
        {
          value: "pop",
          label: "弹出提示",
        },
        {
          value: "min",
          label: "最小化",
        },
        {
          value: "exit",
          label: "退出软件",
        },
      ],
      appearanceList: [
        {
          value: 0,
          label: "自适应",
        },
        {
          value: 1,
          label: "暗黑模式",
        },
        {
          value: 2,
          label: "浅色模式",
        },
      ],
      form: {
        ipBlackList: [],
      },
    };
  },
  created () {
    this.$dicts.getSystemDict();
    this.getMessage();
  },
  methods: {
    getSystemDict (type, row) {
      let arr = JSON.parse(window.localStorage.getItem(`dicts`));
      if (row) {
        return arr[type].find((x) => x.value == row)?.label;
      } else {
        return arr[type];
      }
    },
    async getMessage () {
      const { code, data, msg } = await this.$apis
        .get(`/admin_config_list`)
        .catch((e) => ({
          code: 1,
        }))
        .finally(() => { });
      if (code == 0) {
        data.blockDomainList = data.blockDomainList.join(",");
        this.form = { ...this.form, ...data };
      } else {
        this.$message.error(msg);
      }
    },
    async submit () {
      this.loading = true;
      const params = {
        ...this.form,
        blockDomainList: this.form.blockDomainList.split(","),
      };
      const { code, msg } = await this.$apis
        .post(`/admin_mdf_config`, params)
        .catch((e) => ({
          code: 1,
        }))
        .finally(() => {
          this.loading = false;
        });
      if (code == 0) {
        this.$message({
          type: "success",
          message: "操作成功",
        });
      } else {
        this.$message.error(msg);
      }
    },
    addBlackList () {
      this.form.ipBlackList.push("");
    },
    delBlackList (index) {
      this.form.ipBlackList.splice(index, 1);
    },
  },

};
</script>

<style lang="scss" scoped>
.addBlackList {
  display: flex;
  line-height: 28px;
  margin-bottom: 10px;

  i {
    cursor: pointer;
    display: block;
    width: 28px;
    height: 28px;
    font-size: 23px;
    color: white;
    line-height: 28px;
    text-align: center;
    margin-right: 10px;
    border-radius: 50%;
    background-color: #22b14c;
  }
}

.blackList {
  .input {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .el-input {
      margin-bottom: 5px;
    }

    i {
      cursor: pointer;
      width: 24px;
      height: 24px;
      font-size: 10px;
      color: white;
      line-height: 24px;
      text-align: center;
      margin-left: 10px;
      border-radius: 50%;
      background-color: #22b14c;
      flex-shrink: 0;
    }
  }
}

.submit {
  display: flex;
  justify-content: end;
  margin-top: 10px;
}
</style>
