<template>
  <div class="home pages-store">
    <div class="flex-sw-center" style="height: 50px; width: 100%">
      <div class="flex-start-center">
        <el-input placeholder="用户名" v-model.trim="parmes.username" style="width: 180px; margin-right: 15px" @keydown.enter.native="getMessage()"></el-input>
        <el-input placeholder="空间名" v-model.trim="parmes.workspaceName" style="width: 180px; margin-right: 15px" @keydown.enter.native="getMessage()"></el-input>
        <el-date-picker v-model="parmes.time" type="datetimerange" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd HH:mm:ss" :default-time="['00:00:00', '23:59:59']"
          @change="changeEvent">
        </el-date-picker>
        <el-button class="h30" style="margin-left: 15px" type="success" size="mini" icon="el-icon-search" @click="getMessage(true)">
          查询
        </el-button>
        <el-button class="h30" type="warning" size="mini" icon="el-icon-refresh" @click="
          reSetParmes();
        getMessage(true);
        ">
          重置
        </el-button>
      </div>
    </div>

    <div-table :loading="loading" :fromList="fromList" :fromData="fromData" :pageSize="pageSize" :pageValue="pageValue" :pageSizeList="pageSizeList" :total="total" @up-page-size="pageSize = $event"
      @up-page-value="pageValue = $event" @get-message="getMessage($event)">
    </div-table>
  </div>
</template>

<script>
import divTable from "./components/div-table.vue";
export default {
  components: {
    divTable,
  },
  data () {
    return {
      parmes: { startime: "", endtime: "" },
      loading: false,
      fromList: [
        {
          label: `用户名`,
          key: `userName`,
        },
        {
          label: `空间名`,
          key: `workspaceName`,
        },
        {
          label: `操作类型`,
          key: `operateType`,
        },
        {
          label: `操作详情`,
          key: `operateDetail`,
        },
        {
          label: `操作时间`,
          key: `operateTime`,
        },
        {
          label: `操作状态`,
          key: `status`,
        },
        {
          label: `位置信息`,
          key: `location`,
        },
        {
          label: `操作模块`,
          key: `operateModule`,
        },
        {
          label: `国家编码`,
          key: `countryCode`,
        },
      ],
      fromData: [],
      pageSize: 15,
      pageSizeList: [3, 15, 200, 300, 400],
      pageValue: 1,
      total: 0,
    };
  },
  async mounted () {
    this.reSetParmes();
    this.getMessage();
  },

  watch: {},
  methods: {
    reSetParmes () {
      this.parmes = {
        username: "",
        startime: "",
        endtime: "",
        time: "",
      };
    },
    changeEvent (e) {
      this.parmes.startime = e[0];
      this.parmes.endtime = e[1];
      this.getMessage(true);
    },
    async getMessage (boole = false) {
      if (boole) {
        this.pageValue = 1;
      }
      this.loading = true;
      const {
        code,
        data: { rows, total },
        msg
      } = await this.$apis
        .get(`/admin_operate_history`, {
          page_index: this.pageValue,
          page_size: this.pageSize,
          ...this.parmes,
        })
        .catch((e) => ({
          code: 1,
        }))
        .finally(() => {
          this.loading = false;
        });
      if (code == 0) {
        this.fromData = rows;
        this.total = total;
      } else {
        this.$message.error(msg);
      }

      this.loading = false;
    },
    // handleSizeChange(val) {
    //   console.log(`${val} items per page`);
    //   this.pageSize = val;
    //   this.getMessage();
    // },
    // handleCurrentChange(val) {
    //   console.log(`current page: ${val}`);
    //   this.pageValue = val;
    //   this.getMessage();
    // },
  },
};
</script>

<style lang="scss" scoped>
.home {
  background-color: #fff;
}

.h30 {
  height: 30px;
}
</style>
