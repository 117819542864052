<template>
  <div class="home pages-store">
    <div class="flex-sw-center" style="height: 50px; width: 100%">
      <div class="flex-start-center">
        <el-input placeholder="代理标题" v-model.trim="parmes.proxyTitle" style="width: 180px; margin-right: 15px" @keydown.enter.native="getMessage(true)"></el-input>
        <el-button class="h30" style="margin-left: 15px" type="success" size="mini" icon="el-icon-search" @click="getMessage(true)">
          查询
        </el-button>
        <el-button class="h30" type="warning" size="mini" icon="el-icon-refresh" @click="
          reSetParmes();
        getMessage(true);
        ">
          重置
        </el-button>
      </div>

      <div class="flex-start-center" style="margin-left: 20px">
        <el-button type="primary" size="mini" icon="el-icon-plus" @click="
          fromInit();
        from_type = `add`;
        dialog = true;
        ">
          新增
        </el-button>
      </div>
    </div>
    <div-table :loading="loading" :fromList="tableColumns" :fromData="fromData" :pageSize="pageSize" :pageValue="pageValue" :pageSizeList="pageSizeList" :total="total"
      @up-page-size="pageSize = $event" @up-page-value="pageValue = $event" @get-message="getMessage($event)">
      <template v-slot:proxyUser="{ data }">
        <p class="openingTime">
          {{ `${data.rows.proxyUserName}:${data.rows.proxyPassword}` }}
        </p>
      </template>
      <template v-slot:time="{ data }">
        <p class="openingTime">{{ data.rows.expireTime }}</p>
      </template>
      <template v-slot:workspace="{ data }">
        <p>ID: {{ data.rows.workspaceId }}</p>
        <p> {{ data.rows.workspaceName }}</p>
      </template>
      <template v-slot:inputType="{ data }">
        {{ data.rows.inputType == 1 ? "代理资源" : "窗口填写" }}
      </template>
      <template v-slot:operate="{ data }">
        <div :class="data.rows.source != 'platform'
          ? 'operate flex-sw-center'
          : 'operate flex-center-center'
          ">
          <el-link v-show="data.rows.source != 'platform'" :underline="false" type="warning" @click="changeList(data.rows, 'edit')">编辑</el-link>

          <el-link v-show="data.rows.source != 'platform'" :underline="false" type="danger" @click="changeList(data.rows, 'del')">删除</el-link>
          <el-link v-show="!data.rows.iconLoading" :underline="false" type="primary" :style="data.rows.checkState == 1
            ? 'color:#2692FF'
            : data.rows.checkState == 2
              ? 'color:#67C23A'
              : 'color:#ff0000'
            " @click="changeList(data.rows, 'check')">{{
              data.rows.checkState == 1
                ? "检测"
                : data.rows.checkState == 2
                  ? "有效"
                  : "失效"
            }}</el-link>
          <i v-show="data.rows.iconLoading" class="el-icon-loading"></i>
        </div>
      </template>
    </div-table>
    <div-table-detail title="代理详情" :list="fromList" :data="windowMessage" :visible.sync="drawer"></div-table-detail>

    <el-dialog :title="from_type == 'add' ? '新增' : '编辑'" :visible.sync="dialog" width="30%">
      <el-form lable-postition="right" ref="form" label-width="120px">
        <el-form-item v-for="(value, index) in showPageList(
          dialogList,
          from_type == 'add' ? addList : editList
        )" :key="index" :label="value.label">
          <el-select v-if="value.type == 'select'" v-model="from[value.key]" @change="changeType($event)"
            placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
          <template v-else-if="value.type == 'radio'">
            <el-radio v-model="from[value.key]" :label="1">启用</el-radio>
            <el-radio v-model="from[value.key]" :label="0">禁用</el-radio>
          </template>

          <el-upload v-else-if="value.key == 'proxyIcon'" class="avatar-uploader" action="-" :show-file-list="false"
            :limit="1" :before-upload="beforeUpload">
            <img v-if="from.proxyIcon" :src="from.proxyIcon" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>

          <el-input v-else style="margin-bottom: 10px" v-model="from[value.key]" placeholder="请输入"
            :disabled="value.disabled || false"></el-input>
        </el-form-item>
      </el-form>
      <div class="flex-end-center" style="margin-top: 15px">
        <el-button type="primary" @click="submit()"> 提交</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import divTable from "../components/div-table.vue";
import divTableDetail from '../components/div-table-detail.vue';
import aliOss from '@/mixins/ali-oss';

export default {
  mixins: [aliOss],
  components: {
    divTable,
    divTableDetail
  },
  data () {
    return {
      filtercountryList: [],
      selectList: [
        { label: "住宅IP", value: "resi" },
        { label: "机房IP", value: "dc" },
        { label: "ipv6", value: "IPV6" },
      ],
      selectList1: [
        { label: "手工导入", value: "import" },
        { label: "平台购买", value: "platform" },
      ],
      from_type: "",
      from: {},
      parmes: {},
      loading: false,
      fromList: [
        {
          width: "80px",
          label: `ID`,
          key: `id`,
          slot: "id",
          tableView: true,
          click: (value) => {
            this.drawer = true;
            this.windowMessage = value;
          },
          trans: (value) => {
            return `color: blue;font-weight: bold;cursor: pointer;`;
          },
        },
        {
          label: `代理标题`,
          key: `proxyTitle`,
          tableView: true
        },
        {
          label: `代理ICON`,
          key: `proxyIcon`,
          tableView: true
        },
        {
          label: `代理描述`,
          key: `proxyDesc`,
          tableView: true
        },
        {
          label: `代理跳转地址`,
          key: `proxyJumpAddress`,
          tableView: true
        },
        {
          label: `代理访问地址`,
          key: `proxyVisitAddress`,
          tableView: true
        },
        {
          label: `启用状态`,
          key: `proxyStatus`,
          tableView: true,
          transValue: (value) => {
            return value == 1 ? "启用" : "禁用";
          },
        },
        {
          label: `创建时间`,
          key: `createTime`,
          tableView: true
        },
        {
          label: `修改时间`,
          key: `updateTime`,
          tableView: true
        },
        {
          label: `操作`,
          key: `operate`,
          tableOnly: true,
          slot: `operate`,
          width: "120px",
        },
      ],
      fromData: [],
      pageSize: 15,
      pageSizeList: [3, 15, 200, 300, 400],
      pageValue: 1,
      total: 0,
      // 抽屉
      windowMessage: {},
      drawer: false,
      // 弹窗
      dialog: false,
      dialogList: [
        {
          label: `ID`,
          key: `id`,
          type: `text`,
          disabled: true,
        },
        {
          label: `代理标题`,
          key: `proxyTitle`,
          type: `text`,
        },
        {
          label: `代理图标`,
          key: `proxyIcon`,
        },
        {
          label: `代理描述`,
          key: `proxyDesc`,
        },
        {
          label: `代理跳转地址`,
          key: `proxyJumpAddress`,
        },
        {
          label: `代理访问地址`,
          key: `proxyVisitAddress`,
        },
        {
          label: `代理状态`,
          key: `proxyStatus`,
          type: `radio`,
          defaultValue: 1,
        },
      ],
      addList: [
        "proxyTitle",
        "proxyIcon",
        "proxyDesc",
        "proxyJumpAddress",
        "proxyVisitAddress",
        "proxyStatus",
      ],
      editList: [
        `id`,
        "proxyTitle",
        "proxyIcon",
        "proxyDesc",
        "proxyJumpAddress",
        "proxyVisitAddress",
        "proxyStatus",
      ],
    };
  },
  computed: {
    tableColumns ({ fromList }) {
      // tableView: 显示在表格中
      // tableOnly: 仅在表格中显示
      return fromList.filter((item) => item.tableView || item.tableOnly)
    }
  },
  created () {
    this.$dicts.getSystemDict();
  },
  async mounted () {
    this.reSetParmes();
    this.getMessage();
    this.getSystemDict();
  },

  watch: {},
  methods: {
    beforeUpload (file) {
      const fileName = `/public/static/platform_icon/${file.name}`
      this.ossClient.put(fileName, file).then(({ res }) => {
        this.from.proxyIcon = res.requestUrls[0]?.split("?")[0];
        this.from = { ...this.from };
      }).catch((e) => {
        this.$message.error("上传ICON失败");
        console.error("upload error: %j", e);
      });
      // // 阻止默认上传行为
      return false;
    },
    filterMethod (val) {
      this.from.country = val;
      this.filtercountryList = this.dialogList
        .find((x) => x.key == "country")
        .selectList.filter(
          (x) => x.value.indexOf(val) == 0 || x.label.indexOf(val) == 0
        );
    },
    countryIcon ({ value }) {
      return require(`@/assets/country/${value}.svg`);
    },
    //
    putSvg (val) {
      return "@/assets/country/" + val + ".svg";
    },
    getSystemDict (type) {
      let list = [
        "proxyType",
        "ipType",
        "proxyNetwork",
        "protocol",
        "country",
        "source",
      ];
      let arr = JSON.parse(window.localStorage.getItem(`dicts`));
      list.forEach((x) => {
        this.dialogList.forEach((element) => {
          if (element.key == x) {
            if (x == "country") {
              this.filtercountryList = arr[x];
            }
            element.selectList = arr[x];
          }
        });
      });
    },
    showPageList (arr, filterateArr) {
      let newArr = [];
      arr.forEach((item) => {
        filterateArr.indexOf(item.key) != -1 ? newArr.push(item) : "";
      });
      return newArr;
    },
    changeList (obj, type) {
      this.from_type = type;
      if (type == `edit`) {
        this.dialog = true;
        this.from = Object.assign({}, obj);
      } else if (type == `del`) {
        this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.delete(obj);
          })
          .finally(() => {
            this.loading = false;
          });
      } else if (type == `check`) {
        this.check(obj);
      }
    },
    async check (obj) {
      this.fromData.find((x) => x.id == obj.id).iconLoading = true;
      const { code, data, msg } = await this.$apis
        .post(`/admin_check_proxy`, {
          id: obj.id,
        })
        .catch((e) => ({
          code: 1,
        }))
        .finally(() => {
          this.fromData.find((x) => x.id == obj.id).iconLoading = false;
        });
      if (code == 0) {
        this.fromData.find((x) => x.id == obj.id).checkState = 2;
      } else {
        this.fromData.find((x) => x.id == obj.id).checkState = 3;
      }
    },
    async delete (obj) {
      const { code, data, msg } = await this.$apis
        .get(`/admin_del_proxy_recommend_info`, {
          id: obj.id,
        })
        .catch((e) => ({
          code: 1,
        }))
        .finally(() => {
          this.loading = false;
        });
      if (code == 0) {
        this.$message.success("删除成功");
        this.getMessage();
      } else {
        this.$message.error(msg);
      }
    },
    async submit () {
      const {id, ...rest} = this.from
      const { code, data, msg } = await this.$apis
        .post(
          `${this.from_type == "add" ? "/admin_add_proxy_recommend_info" : "/admin_mdf_proxy_recommend_info"
          }`,
          this.from_type == "add" ? rest : { id, ...rest }
        )
        .catch((e) => ({
          code: 1,
        }))
        .finally(() => {
          this.loading = false;
        });
      if (code == 0) {
        this.getMessage();
        this.dialog = false;
        this.$message.success("操作成功");
      } else {
        this.$message.error(msg);
      }
    },
    fromInit () {
      let obj = {};
      this.dialogList.forEach((elem) => {
        obj[elem.key] =
          (elem.defaultValue instanceof Function
            ? elem.defaultValue()
            : elem.defaultValue) || ``;
        obj[elem.key].length > 0 ? (obj[elem.key] = []) : "";
      });

      this.from = obj;
    },
    handleClose (done) {
      return done();
    },
    reSetParmes () {
      this.parmes = {
        username: "",
      };
    },
    async getMessage (boole = false) {
      if (boole) {
        this.pageValue = 1;
      }
      this.loading = true;
      const {
        code,
        data: { rows, total },
        msg,
      } = await this.$apis
        .get(`/admin_get_proxy_recommend_info`, {
          page_index: this.pageValue,
          page_size: this.pageSize,
          ...this.parmes,
        })
        .catch((e) => ({
          code: 1,
        }))
        .finally(() => {
          this.loading = false;
        });
      if (code == 0) {
        rows.forEach((item) => {
          item.iconLoading = false;
          item.checkState = 1;
          item.hostPort = `${item.host}:${item.port}`;
        });
        this.fromData = rows;
        this.total = total;
      } else {
        this.$message.error(msg);
      }

      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.home {
  background-color: #fff;
}

::v-deep .el-date-editor.el-input {
  width: 100%;
}

.h30 {
  height: 30px;
}

.openingTime {
  white-space: normal;
  margin: 0;
}
</style>
